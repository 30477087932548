import React, { FC, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PrimaryButton, SelectBox, Spacer, TextInput } from '../components/UIkit';
import { useSelect, useStringChangeEvent } from 'lib/customHooks';
import { sendForm } from 'reducks/contact/operation';
import { findWithNullCheck } from '../lib/customArrayMethods';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: '32px 16px',
      margin: '0 auto',
      maxWidth: 768,
      width: 'cacl(100% - 16px)',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 28,
        fontWeight: 600,
        textAlign: 'center',
      },
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
      },
    },
    buttonArea: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      margin: '0 auto',
    },
  })
);

const Contact: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState<string>(''),
    [description, setDescription] = useState<string>(''),
    [email, setEmail] = useState<string>(''),
    [kana, setKana] = useState<string>(''),
    [name, setName] = useState<string>(''),
    [phoneNumber, setPhoneNumber] = useState<string>(''),
    [type, setType] = useState<string>('');

  const contactTypes = useMemo(
    () => [
      { id: 'service', name: 'COCOLのサービス内容について' },
      { id: 'trouble', name: 'COCOLでのトラブルについて' },
      { id: 'login', name: 'COCOLへのログインについて' },
      { id: 'purchase', name: 'COCOL内での購入について' },
      { id: 'interview', name: 'COCOLへの取材依頼について' },
    ],
    []
  );

  return (
    <section className={classes.section}>
      <p>
        COCOLへの各種お問い合わせを承っています。
        <br />
        ★の項目は必須項目です。
      </p>
      <p>
        まずはアプリ内の「ヘルプ」をご確認ください。
        <br />
        それでも解決しない場合、こちらからお問い合わせください。
      </p>

      <Spacer size={'medium'} />
      <TextInput
        fullWidth={true}
        label={'お名前'}
        multiline={false}
        onChange={useStringChangeEvent(setName)}
        placeholder={'例) 山田 太郎'}
        required={true}
        rows={1}
        type={'text'}
        value={name}
      />
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'フリガナ'}
        multiline={false}
        onChange={useStringChangeEvent(setKana)}
        placeholder={'例) ヤマダ タロウ'}
        required={true}
        rows={1}
        type={'text'}
        value={kana}
      />
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'会社名'}
        multiline={false}
        onChange={useStringChangeEvent(setCompanyName)}
        placeholder={'例) 株式会社LINKS'}
        required={false}
        rows={1}
        type={'text'}
        value={companyName}
      />
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'メールアドレス'}
        multiline={false}
        onChange={useStringChangeEvent(setEmail)}
        placeholder={'例) example@cocol.tokyo'}
        required={true}
        rows={1}
        type={'email'}
        value={email}
      />
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'電話番号'}
        multiline={false}
        onChange={useStringChangeEvent(setPhoneNumber)}
        placeholder={'例) 096-247-6944'}
        required={false}
        rows={1}
        type={'tel'}
        value={phoneNumber}
      />
      <Spacer size={'small'} />
      <SelectBox
        label={'お問い合わせ種別※'}
        options={contactTypes}
        required={true}
        select={useSelect(setType)}
        value={type}
      />
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'お問い合わせ内容'}
        multiline={true}
        onChange={useStringChangeEvent(setDescription)}
        required={true}
        rows={10}
        type={'text'}
        value={description}
      />
      <Spacer size={'small'} />
      <div className={classes.buttonArea}>
        <PrimaryButton
          label={'送信する'}
          onClick={() => {
            dispatch(
              sendForm({
                company_name: companyName,
                contact_id: '',
                description: description,
                email: email,
                is_supported: false,
                kana: kana,
                name: name,
                phone_number: phoneNumber,
                type_id: type,
                type_content: findWithNullCheck(contactTypes, 'id', type, 'name', ''),
              })
            );
          }}
        />
      </div>
    </section>
  );
};

export default Contact;
