import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Theme} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/styles';
import {GreyButton, Spacer } from "components/UIkit";
import {push} from "connected-react-router";
import {fb, auth} from 'firebase/index';
import * as firebaseui from "firebaseui";
import utility from "assets/css/utility.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: '0 auto',
      maxWidth: 450,
      padding: '64px 16px',
      textAlign: 'left',
      width: '100%'
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      width: '100%'
    },
    description: {
      fontSize: 14,
    },
  })
);

const PaymentPhoneSignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const uiConfig = {
      signInSuccessUrl: "http://localhost:3000/payment", //This URL is used to return to that page when we got success response for phone authentication.
      signInOptions: [
        {
          provider: fb.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'JP'
        }
      ],
      tosUrl: "http://localhost:3000/payment"
    };
    const ui = new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);
  },[])

  return (
    <section className={classes.section}>
      <h2 className={utility.title}>電話番号でログイン</h2>
      <Spacer size={"small"}/>
      <p className={classes.description}>クレジットカードでの決済にはログインが必要です。</p>
      <Spacer size={"large"}/>
      <div className={classes.content}>
        <div id="firebaseui-auth-container" />
        <Spacer size={"medium"}/>
        <GreyButton
          label={"メールアドレスでログイン"}
          onClick={() => dispatch(push('/payment/signin'))}
        />
      </div>
    </section>
  );
};

export default PaymentPhoneSignIn;
