import React, {FC} from 'react';
import styles from 'assets/css/content.module.css'

const Law: FC = () => {
  return (
    <section className={styles.content}>
      <h1>特定商取引法に基づく表示</h1>

      <p>株式会社LINKS(以下「当社」といいます)が提供するCOCOLのサービス関する特定商取引法に基づく表示は、以下のとおりです。</p>

      <h2>１（代金等について）</h2>
      <p>当社が提供する有料会員（以下「商品」といいます）の価格は、別表●のとおりです（商品の提供に要する通信料等は、お客様にご負担いただきます）。また、代金全額のお支払いをいただいた後に、商品を提供させていただきます。</p>
      <p>お支払いには、当社指定のクレジットカード決済、スマートフォン用アプリ内課金をご利用になれます。</p>
      <h2>２（利用期間）</h2>
      <p>お客様は、商品をご購入いただいた日から６ヶ月間に限って、商品をご利用いただけます。６ヶ月を超えた場合には、その理由を問わず、商品をご利用できなくなりますので、ご注意ください。</p>
      <h2>３（解除等）</h2>
      <p>お客様が商品を購入された後には、いかなる場合であっても、返品、交換、返金には応じられません。</p>
      <h2>４（動作環境）</h2>
      <p>商品は、iOSのバージョン13.7以上の環境でのご利用をお願い致します。</p>
      <h2>５（事業者の表示）</h2>
      <p>
        〒860-0807<br/>
        熊本市中央区下通一丁目8番20号下通センタービル5階<br/>
        株式会社LINKS<br/>
        代表取締役 杉浦 健<br/>
        電話番号 096-247-6944<br/>
        メールアドレス <a href={"mailto:info@cocol.tokyo"} target={"_blank"} rel="noopener noreferrer">info@cocol.tokyo</a>
      </p>

    </section>
  );
};

export default Law;