import { db, FirebaseTimestamp } from 'firebase/index';
import { Action, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getMessage } from 'lib/messages';
import { isValidEmailFormat, isValidMaxLength, isValidMinLength } from '../../lib/commonFunc';
import { WithdrawalType } from './types';
import { hideLoadingAction, showLoadingAction } from '../loading/action';

const withdrawalRef = db.collection('withdrawal');

const sendEmail = async (email: string, nickname: string) => {
  const headers = new Headers();
  headers.set('Content-type', 'application/json; charset=utf-8');

  const subject = '【COCOL】退会処理を受け付けました';
  const body = `
    <p>${nickname}様</p>
    <p>
      COCOL運営事務局です。<br/>
      いつもCOCOLをご利用いただき、誠にありがとうございます。
    </p>
    <p>
      先ほど、退会処理を受け付けました。<br/>
      担当者にて内容を確認し、退会手続きを行います。<br/>
      退会処理が完了しましたら、メールにてご連絡差し上げます。
    </p>
    <p>
      退会処理完了後、データは完全に削除され、再登録しても過去のデータは引き継げません。<br/>
      ご了承ください。
    </p>
    <p>
      この度は、COCOLをご利用いただき誠にありがとうございました。
    </p>
    <p>
      =============<br/>
      運営会社：株式会社LINKS<br/>
      その他のお問い合わせは<a href="https://cocol.tokyo/contact">コチラ</a>から<br/>
      =============      
    </p>
  `;

  return await fetch('https://z603oku7n4.execute-api.us-east-1.amazonaws.com/v1/mail', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: JSON.stringify({
      sendTo: email,
      subject: subject,
      body: body,
    }),
  });
};

export const sendWithdrawalForm = (value: WithdrawalType, agreements: string[]) => {
  return async (dispatch: Dispatch<Action>) => {
    // Validations
    if (value.nickname === '') {
      alert(getMessage('2200'));
      return false;
    }
    if (value.login_type === 'email' && value.auth_id === '') {
      alert(getMessage('1300'));
      return false;
    }
    if (value.login_type === 'email' && !isValidEmailFormat(value.auth_id)) {
      alert(getMessage('1305'));
      return false;
    }
    if (value.login_type === 'phone_number' && value.auth_id === '') {
      alert(getMessage('1100'));
      return false;
    }
    if (value.login_type === 'phone_number' && !isValidMinLength(value.auth_id, 10)) {
      alert(getMessage('1102'));
      return false;
    }
    if (value.login_type === 'phone_number' && !isValidMaxLength(value.auth_id, 13)) {
      alert(getMessage('1103'));
      return false;
    }
    if (value.login_type === 'phone_number' && value.email === '') {
      alert(getMessage('1300'));
      return false;
    }
    if (value.login_type === 'phone_number' && !isValidEmailFormat(value.email)) {
      alert(getMessage('1305'));
      return false;
    }
    if (value.reason_id === '') {
      alert(getMessage('2201'));
      return false;
    }
    if (agreements.length < 3) {
      alert(getMessage('2202'));
      return false;
    }

    dispatch(showLoadingAction());
    const ref = withdrawalRef.doc();
    const now = FirebaseTimestamp.now();

    value.withdrawal_id = ref.id;
    value.created_at = now;
    value.updated_at = now;

    if (value.login_type === 'email') {
      value.email = value.auth_id;
    }

    // Format phone number to international phone number
    if (value.login_type === 'phone_number') {
      value.auth_id = '+81' + value.auth_id.slice(1).replace(/-/g, '')
    }

    return withdrawalRef
      .doc(value.withdrawal_id)
      .set(value)
      .then(async () => {
        const res = await sendEmail(value.email, value.nickname);

        if (res.status === 200) {
          dispatch(hideLoadingAction());
          alert('退会処理を受け付けました。\nご入力いただいたメールアドレス宛のメールをご確認ください。');
          return dispatch(push('/'));
        } else {
          dispatch(hideLoadingAction());
          alert('退会処理に失敗しました。\n通信環境をご確認いただき、もう1度お試しください。');
          return false;
        }
      })
      .catch((error) => {
        dispatch(hideLoadingAction());
        alert('退会処理に失敗しました。\n通信環境をご確認いただき、もう1度お試しください。');
        console.error(error);
        return false;
      });
  };
};
