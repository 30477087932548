export const getMessage = (code: string): string => {
  switch (code) {
    // Phone number validation
    case '1100':
      return '電話番号が未入力です。電話番号を入力してください。';
    case '1101':
      return '電話番号が空白です。電話番号を入力してください。';
    case '1102':
      return '電話番号が短すぎます。電話番号を確認してください。';
    case '1103':
      return '電話番号が長すぎます。ハイフン記号などを省いて入力してください。';
    // Email
    case '1300':
      return 'メールアドレスが未入力です。メールアドレスを入力してください。';
    case '1301':
      return 'メールアドレスが空白です。メールアドレスを入力してください。';
    case '1302':
      return 'メールアドレスが短すぎます。6文字以上で再度入力してください。';
    case '1303':
      return 'メールアドレスが長すぎます。128文字以内で再度入力してください。';
    case '1305':
      return 'メールアドレスの形式が不正です。メールアドレスを確認してください。';
    // Contact Validation
    case '2100':
      return '名前が未入力です。名前をご入力ください。';
    case '2101':
      return 'フリガナが未入力です。フリガナをご入力ください。';
    case '2102':
      return 'お問い合わせ種別が未選択です。お問い合わせ種別をご選択ください。';
    case '2103':
      return 'お問い合わせ内容が未入力です。お問い合わせ内容をご入力ください。';
    // Withdrawal Validation
    case '2200':
      return 'ユーザー名が未入力です。ユーザー名をご入力ください。';
    case '2201':
      return '退会理由が未選択です。退会理由をご選択ください。';
    case '2202':
      return '全ての項目に同意してチェックを入れてください。';
    default:
      return '入力値に誤りがあります。';
  }
};
