import React, {FC} from 'react';
import {fb, auth} from 'firebase/index';
const FirebaseUIAuth = require("react-firebaseui-localized");

const config = {
  signInFlow: "popup",
  signInSuccessUrl: "http://localhost:3000/payment", //This URL is used to return to that page when we got success response for phone authentication.
  signInOptions: [fb.auth.PhoneAuthProvider.PROVIDER_ID],
  tosUrl: "http://localhost:3000/payment"
};

const PhoneUIAuth: FC = () => {
  return (
    <FirebaseUIAuth
      lang={"ja"}
      config={config}
      auth={auth}
      firebase={fb}
    />
  );
};

export default PhoneUIAuth;