import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';

interface ButtonProps {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  size?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    default: {
      backgroundColor: "#5F5F5F",
      borderRadius: 30,
      color: "#fff",
      fontSize: 16,
      height: 60,
      maxWidth: 256,
      width: '100%',
    },
    medium: {
      backgroundColor: "#5F5F5F",
      color: "#fff",
      fontSize: 14,
      height: 36,
      maxWidth: 128,
      width: '100%',
    },
  })
);

const GreyButton = (props: ButtonProps) => {
  const classes = useStyles();
  const classNames = props.size === 'medium' ? classes.medium : classes.default;

  return (
    <Button className={classNames} disabled={props.disabled} variant="contained" onClick={() => props.onClick()}>
      {props.label}
    </Button>
  );
};

export default GreyButton;
