export const firebaseConfig = ((nodeEnv: string, projectId: string | undefined) => {
  if (nodeEnv === 'production' && projectId === 'cocol-prod') {
    return {
      apiKey: 'AIzaSyC24uWW8RWIyKi3jiqD5nHrQxjBy2160Q4',
      authDomain: 'cocol-prod.firebaseapp.com',
      databaseURL: 'https://cocol-prod.firebaseio.com',
      projectId: 'cocol-prod',
      storageBucket: 'cocol-prod.appspot.com',
      messagingSenderId: '655660318883',
      appId: '1:655660318883:web:a26e326e4771c39cf13f16',
    };
  } else {
    return {
      apiKey: 'AIzaSyBQwnvL0hwv8c5J3G9jYzO76sqGAHCSRtg',
      authDomain: 'papakatsu-dev.firebaseapp.com',
      databaseURL: 'https://papakatsu-dev.firebaseio.com',
      projectId: 'papakatsu-dev',
      storageBucket: 'papakatsu-dev.appspot.com',
      messagingSenderId: '884558778508',
      appId: '1:884558778508:web:752517a04b59fa0469aba9',
      measurementId: 'G-SF9HK2FEEJ',
    };
  }
})(process.env.NODE_ENV, process.env.REACT_APP_PROJECT_ID);
