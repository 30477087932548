import {CustomerState} from "reducks/payment/types";

export const SIGN_IN = 'SIGN_IN'
export const paymentSignInAction = (customerState: CustomerState) => {
    return {
        type: 'SIGN_IN',
        payload: customerState,
    }
}

export const SIGN_OUT = 'SIGN_OUT'
export const signOutAction = () => {
    return {
        type: 'SIGN_OUT',
        payload: null,
    }
}
