import React, { useState, useCallback, useEffect, FC } from 'react';
import { db } from 'firebase/index';
import { AnswersList, Chats } from 'components/Chatbot';
import { AnswersType, ChatsType, Dataset, DatasetList } from 'reducks/question/types';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      position: 'relative',
      height: '100vh',
      width: '100%',
    },
    box: {
      background: '#fff',
      boxSizing: 'border-box',
      height: 640,
      maxWidth: 432,
      margin: '0 auto',
      padding: '0 16px',
      width: 'calc(100% - 16px)',

      /* Vertical and horizontal center alignment */
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

const Chatbot: FC = () => {
  const classes = useStyles();

  const [answers, setAnswers] = useState<AnswersType>([]); // 回答コンポーネントに表示するデータ
  const [chats, setChats] = useState<ChatsType>([]); // チャットコンポーネントに表示するデータ
  const [currentId, setCurrentId] = useState<string>('init'); // 現在の質問ID
  const [datasetList, setDataset] = useState<DatasetList>([]); // 質問と回答のデータセット

  // 次の質問をチャットエリアに表示する関数
  useEffect(() => {
    const nextDataset = datasetList.find((dataset) => dataset.question_id === currentId);
    console.log(nextDataset);

    if (nextDataset) {
      // 選択された回答と次の質問をチャットに追加
      const chat = {
        text: nextDataset.question,
        type: 'question',
      };
      setChats((prevChats) => [...prevChats, chat]);

      // 次の回答一覧をセット
      setAnswers(nextDataset.answers);
    }
  }, [datasetList, currentId]);

  // 回答が選択された時に呼ばれる関数
  const selectAnswer = useCallback(
    (selectedAnswer: string, nextId: string) => {
      switch (true) {
        // リンクが選択された時
        case /^https:*/.test(nextId):
          const a = document.createElement('a');
          a.href = nextId;
          a.target = '_self';
          a.click();
          break;
        // 選択された回答をchatsに追加
        default:
          const chat = {
            text: selectedAnswer,
            type: 'answer',
          };
          setChats((prevChats) => [...prevChats, chat]);

          setTimeout(() => setCurrentId(nextId), 500);
          break;
      }
    },
    [answers]
  );

  useEffect(() => {
    const _datasetList: DatasetList = [];

    // Fetch questions dataset from Firestore
    db.collection('question')
      .get()
      .then((snapshots) => {
        snapshots.forEach((doc) => {
          _datasetList.push(doc.data() as Dataset);
        });

        setDataset([..._datasetList]);
      });
  }, []);

  // 最新のチャットが見えるように、スクロール位置の頂点をスクロール領域の最下部に設定する
  useEffect(() => {
    const scrollArea = document.getElementById('scroll-area');
    if (scrollArea) {
      scrollArea.scrollTop = scrollArea.scrollHeight;
    }
  });

  return (
    <section className={classes.section}>
      <div className={classes.box}>
        {datasetList.length > 0 && (
          <>
            <Chats chats={chats} />
            <AnswersList answers={answers} select={selectAnswer} />
          </>
        )}
      </div>
    </section>
  );
};

export default Chatbot;
