import React, {FC} from 'react';
import styles from 'assets/css/content.module.css'

const TermsOfService: FC = () => {

  return (
    <section className={styles.content}>
      <h1>COCOL サービス利用規約</h1>

      <p>この規約（以下「本規約」といいます）は、株式会社LINKS（以下「当社」といいます）が提供するCOCOLに関する全てのサービス（以下「本サービス」といいます）の利用に関する条件を、本サービスを利用するお客様（以下「利用者」といいます）と当社との間で定めるものです。</p>

      <h2>第１条（定義）</h2>
      <p>本規約における次の用語の定義は、以下のとおりです。</p>
      <ul>
        <li>有料サービス：本サービスのうち、利用者の自由な意思に基づいて、他の利用者と十分なコミュニケーションをとるために利用する有料のサービス</li>
        <li>有料会員：有料サービスを利用するために、有料サービスの利用者登録をした利用者（有料サービスは、代金を支払った日から利用可能ですが、利用開始日から6ヶ月で、利用できなくなりますので、ご注意下さい）</li>
        <li>登録希望者：本サービスの会員としての登録を希望した利用者</li>
        <li>登録情報：登録希望者が、当社に対し、本サービスの会員登録のために提供した情報（氏名、年齢、住所、電話番号、その他必要な当社が定める情報）</li>
        <li>コンテンツ：本サービスを通じて利用者が入力した、プロフィール、メッセージなどの一切の情報</li>
        <li>ユーザID：利用者を特定するために、会員登録後に、利用者自らが設定し、または、当社が割り付けた識別情報</li>
      </ul>

      <h2>第２条（規約への同意）</h2>
      <ol>
        <li>利用者が本サービスを利用する場合には、本規約の定めに従わなければなりません。</li>
        <li>利用者が本サービスを利用するためには、本規約に完全な同意（同意として不可逆的に有効な同意）をする必要があります。</li>
        <li>利用者が会員登録をするか否かに関わらず、本サービスを利用した場合、本規約に完全な同意をしたものとみなされます。</li>
      </ol>

      <h2>第３条（規約の適用）</h2>
      <p>本規約の規定が、利用者と当社との間で適用される関連法令に反するとされた場合、当該規定は、その限りにおいて、当該利用者との契約には適用されないものとします。ただし、この場合でも、本規約の他の規定の効力には影響しないものとします。</p>

      <h2>第４条（本サービスの内容）</h2>
      <ol>
        <li>本サービスは、日本在住の満18歳以上の方のパートナー探しをサポートするものです。本サービスは、一部のサービス・機能を除き、無料でご利用いただけますが、他の利用者と十分なコミュニケーションをとるためには、有料サービスの利用を推奨しています。</li>
        <li>本サービスは、パートナーを見つけることを保証するものではありません。</li>
        <li>有料サービスの利用には、当社が別途定める月額利用料の支払、または、当社が別途定める●の購入・使用が必要となります。</li>
        <li>有料サービスは、利用開始日から6ヶ月で利用できなくなります。理由を問わず、6ヶ月を超過した場合には、有料サービスの利用、未利用分の返金には応じられませんので、ご注意下さい。</li>
      </ol>

      <h2>第5条（本サービスの提供、無保証、変更および中止）</h2>
      <ol>
        <li>当社は、本サービスの利用者を、会員登録の有無、年齢、本人確認の有無、その他、当社が必要と判断する条件を満たした者に限定することができます。</li>
        <li>当社は、当社が必要と判断する場合、あらかじめ利用者に通知することなく、本サービスの全部または一部の内容を変更し、また、その提供を中止することができ、本規定に基づいて当社が行った措置によって利用者に生じた損害については、一切の責任を負いません。</li>
        <li>当社は、本サービスに事実上または法律上の不具合（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みますが、これらに限りません）がないことを保証するものではありません。</li>
      </ol>

      <h2>第6条（利用資格）</h2>
      <ol>
        <li>本サービスを利用できるのは、満18歳以上の日本在住の方に限ります。</li>
        <li>年齢等、本サービスを利用できるか否かについては、当社において、登録情報をもとに判断します。かかる判断は、登録情報をもとにするため、当社は、全ての本サービスの利用者が満18歳以上であること、既存パートナーの有無等について、一切、保証しません。</li>
        <li>本サービスの利用登録後に、登録情報、コンテンツに虚偽、事実不一致等が発見された場合、当社の判断で会員登録を抹消することがあります。当社が、会員登録を抹消することによって、当該被抹消者に発生した損害について、当社は、一切の責任を負いません。なお、本サービスは、利用者の犯罪経歴調査を行っておらず、利用者の経歴も問い合わせず、利用者の申告がなされても内容の確認は行っていません。このため、他の利用者の登録情報、コンテンツの内容の正確性等について、当社は、一切、保証しません。</li>
        <li>登録希望者は、当社に対し、登録情報に提供して、会員登録の申し込みを行うことができます。なお、未成年者は、法定代理人の同意を得なければ、会員登録の申込みを行えません。</li>
        <li>登録希望者は本規約を遵守することを承諾した上で、登録申込を行うものとします。</li>
        <li>当社は、利用者について、公的記録を使用して、犯罪経歴調査等を行うことができるものとしますが、当社は、同調査を行う義務を負いません。</li>
        <li>
          当社は、利用者が下記の事由に相当する場合は、会員登録を拒否でき、会員登録後であっても、会員資格を一時停止または喪失させることができるものとします。<br/>
          ① 本規約当社に違反するおそれがあると当社が判断した場合<br/>
          ② 当社に提供された登録情報やコンテンツの全部または一部につき虚偽、誤記または記載漏れがあった場合<br/>
          ③ 過去に本サービスの利用の登録を取り消されたことがある場合<br/>
          ④ 成年被後見人、被保佐人、被補助人または未成年者のいずれかであり、後見人、保佐人、補助人または法定代理人の同意等を得ていなかった場合<br/>
          ⑤
          反社会的勢力等（暴力団、暴力団対、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合<br/>
          ⑥
          利用料金の支払の遅滞が生じ、または、本サービスの代金決済手段として指定されたクレジットカードや銀行口座の利用が停止され、あるいは、利用者が破産もしくは民事再生の手続の申立てを受け、または利用者自らがそれらの申立てを行うなど、利用者の信用不安が発生したと当社が判断した場合<br/>
          ⑦ アカウントが反社会的勢力またはその構成員や関係者によって登録または使用された場合、もしくはそのおそれがあると当社が判断した場合<br/>
          ⑧ 利用者が一定期間にわたってアカウントまたは特定のサービスを使用していない場合<br/>
          ⑨ その他、利用者との信頼関係が失われた場合など、当社と利用者との契約関係の維持が困難であると当社が判断した場合
        </li>
      </ol>

      <h2>第7条（利用者の責任および注意義務）</h2>
      <ol>
        <li>利用者は、自己の責任に基づいて本サービスを利用するものとし、利用者が公開するコンテンツについても同様とします。また、利用者が公開したコンテンツと、本サービスを通して利用者が他の利用者に転送したコンテンツについて、利用者は単独で責任を負い、利用者は、公開したコンテンツが、正確で、本契約に違反しないことを保証しなければなりません。</li>
        <li>利用者は、本サービスの他の利用者との交流に関しては、単独で責任を負うものとします。当社は、利用者と他の利用者との間で起きた紛争を監視する権利を留保しますが、義務は負いません。</li>
        <li>利用者は、他人の著作物を使用したことなどが原因で紛争、損害賠償の請求などが起こった場合、その損害や責任の一切を自分で負担するものとし、当社に一切の負担を負わせないものとします。</li>
        <li>利用者は、自分の責任において、ユーザIDおよびパスワードを管理、保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
        <li>利用者は、当社に対し、ユーザIDおよびパスワードを第三者に盗用されたことなどによって、利用者のコンテンツの改変や改ざん、個人情報等の無断閲覧などにより発生するあらゆる紛争、損害賠償の請求などについての一切を免責するものとします。</li>
        <li>利用者が、当社に損害を与えた場合には、利用者は、当社に対して、当社が被った一切の損害を賠償するものとします。</li>
        <li>
          当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部または一部の利用を停止または中断することができるものとします。<br/>
          ① 本サービスに係るコンピューター・システムの点検または保守作業を定期的または緊急に行う場合<br/>
          ② コンピューター、通信回線等が事故により停止した場合<br/>
          ③ 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合<br/>
          ④ その他、当社が停止または中断を必要と判断した場合
        </li>
        <li>当社は、利用者に対し、本条に基づいて当社が行った措置によって利用者に生じた損害について一切の責任を負いません。</li>
        <li>有料サービスは、利用開始日から6ヶ月で利用できなくなります。理由を問わず、6ヶ月を超過した場合には、有料サービスの利用、未利用分の返金には応じられませんので、ご注意下さい。</li>
      </ol>

      <h2>第8条（禁止事項）</h2>
      <p>利用者は、本サービスの利用に際して、以下の行為を行ってはならないものとし、利用者がこれらの禁止行為を行った場合、または行うおそれがあると当社が判断した場合、利用者に通知することなく、当社は該当するデータの削除、当該利用者に対して注意を促す表示を行う、または利用制限もしくは強制退会させることができるものとします。ただし、当社は、当該データ等を掲載停止または削除する義務を負うものではなく、また、データの削除および利用制限等の処分にいての説明の義務を負わないものとします。なお、当社は、本条に基づいて当社が行った措置によって利用者に生じた損害について一切の責任を負いません。</p>
      <ol>
        <li>利用者のアイコン画像、サブ写真、プロフィール情報、自己紹介文を無断で使用する行為や、他の利用者のアイコン画像、サブ写真、プロフィール情報、自己紹介文を無断で使用する行為（モザイク処理がなされていても、無断使用と見なします）
        </li>
        <li>他の利用者に対して、詐欺、脅迫、強要または恫喝し、または、第三者の所有する知的所有権を侵害する行為や、著作権、肖像権の侵害を誘発する行為（海賊版コンピューター･プログラムを提供したり、それにリンクを張ったり、製品に組み込まれたコピー防止機能を回避する情報を提供したり、海賊版音楽を提供したり、海賊版音楽ファイルにリンクを張ったりする等の行為を含むがこの限りではない）、あるいは、第三者の名誉や社会的信用を毀損したり、不快感や精神的な損害を与える行為（本サービスを通して知り得た利用者の場合、本サービス外でのやりとりも対象とする）
        </li>
        <li>コンテンツ閲覧者を含む第三者に自己のユーザIDやパスワード、連絡先や他のサービスＩＤを閲覧可能な状態にしておく行為、または、第三者の個人情報を公開する行為、第三者の個人情報の収集を行う行為やコンテンツ閲覧者または第三者に成りすます行為
        </li>
        <li>未成年者が法定代理人の同意を得ずに会員登録およびコンテンツを利用する行為、満18歳未満の者の会員登録およびコンテンツを利用する行為
        </li>
        <li>性描写、残酷な表現、犯罪を誘発する表現、差別表現など、公序良俗に反する行為や児童を騙る行為、児童ポルノ、またはそれに類する内容、あるいは当社が児童ポルノに類すると判断する内容を掲載する行為、性器露出画像、動画、あるいは性器を描写したデータ等、当社が性器を描写した内容であると判断した内容のサイトへのリンクを掲載する行為
        </li>
        <li>本サービスを目的外利用する行為（商用目的の宣伝・広告、求人等の行為、無限連鎖講、マルチ商法、またはそれらに類するもの、その恐れのあるもの、あるいは当社が無限連鎖講、マルチ商法、またはそれに類するもの、その恐れのあるものと判断する内容を掲載する行為等）
        </li>
        <li>本サービスの運営を妨げる行為、あるいは、当社が、本サービスの運営を妨げるおそれがあると判断する量のデータ転送、サーバーに負担をかける行為（不正な連続アクセスなど）、有害なコンピュータウィルス、コード、ファイル、プログラム等を開示する行為、もしくは開示されている場所について示唆する行為、ジャンクメール、チェーンメール、迷惑メール、スパムメールに関連する情報を開示する行為
        </li>
        <li>集団または個人に対するあらゆる種類の人種差別や偏見、憎悪、身体的危害を助長する情報等、オンラインコミュニティーに対して明らかに不快だと思われる情報、他人への嫌がらせを目的としたと推認できる情報、または嫌がらせを支持する情報、嘘あるいは誤解を招く可能性がある情報、違法行為を助長する情報を開示する行為
        </li>
        <li>公序良俗に反する情報や、他人の権利を侵害し、または他人の迷惑となるような情報を、投稿、掲載、開示、提供または送信する行為、本サービスに関連して、反社会的勢力等に直接・間接に利益を提供する行為
        </li>
        <li>法令等に違反する行為
        </li>
        <li>本規約に反する行為、その他当社が不適切であると判断する行為</li>
      </ol>

      <h2>第９条（契約期間および契約の終了）</h2>
      <ol>
        <li>利用者が本サービスを利用する限り、本規約が適用されます。なお、本サービスは特定商取引に関する法律の適用がある特定継続的役務提供ではなく、クリーニング・オフの対象とはなりません。利用者はこの点を理解したうえで、本サービスをご利用ください。
        </li>
        <li>有料サービスの利用期間満了の一定期間（この期間はiPhone、アンドロイドスマートフォン等の端末毎にAPPLE Inc., Google
          Inc.これらの会社の日本法人等が別途定める期間、または、それらの会社を経由しないで当社と契約をしている場合は決済代行会社、クレジットカード会社等が定める期間またはその他当社が別途定める期間となります。）前までに利用者が次項に定める退会申請を完了しない場合、有料サービスの利用期間満了後、当該有料サービスと同一期間の有料サービスの利用が開始されます。
        </li>
        <li>利用者は、①本サービスの退会申請フォームから契約終了の通知を当社に送付し、かつ、②iPhone、アンドロイドスマートフォン、PC等の端末毎にAPPLE Inc., Google Inc.
          これらの会社の日本法人等または当社が別途定める手続を完了することで、退会の申請が可能となります。
        </li>
        <li>退会の効力は、別途当社が定めた場合を除き、前項所定の①及び②の手続が完了し、利用者の有料サービスの利用期間が満了した時に生じるものとします。
        </li>
        <li>利用者が本規約に違反した場合、また、そのおそれがあると当社が判断した場合、当社はいつでも利用者の会員資格を即座に終了させ、コンテンツを削除し、本サービスの利用を終了させることができます。この場合、利用者が入会時に登録した携帯電話番号へのショートメッセージの送信、本サービスのアプリ内での通知または当社が別途定める方法で、当社は終了通知を送ることとします。また、当社は、利用者が本サービスの全部または一部にアクセスすることを、恒久的に禁止することができます。この場合、利用者の有料サービスの利用期間が満了していなくても、当社は、利用者に対し、一切の返金の責任を負いません。
        </li>
        <li>利用者が有料会員ではない場合には、本サービスに最後に接続した日を起算日として、6ヵ月以上本サービスの利用がなければ、当社は、利用者のアカウント抹消することができます。</li>
      </ol>

      <h2>第10条（目的外利用の禁止）</h2>
      <p>利用者が、本サービス、本サービスを構成するデータを、本サービスの利用目的を超えて利用した場合、当社は、それらの行為を差し止める権利ならびにそれらの行為によって当社に生じた損害の賠償を請求する権利を有します。</p>

      <h2>第11条（無料会員と有料会員、および利用料金）</h2>
      <ol>
        <li>本サービスは、一部のサービスおよび機能を除き、無料でご利用いただけます。
        </li>
        <li>他の会員とのメッセージのやり取りといったサービス、他の会員と連絡をとるなどの追加の機能やサービスおよび追加機能を利用するためには、有料会員になる必要があります。最新の有料会員プランと利用料金に関する詳細は、各事項についての説明のページをご覧ください。
        </li>
        <li>有料会員の申し込み後の変更・キャンセルはお受けできません。また、利用者が申し込んだ有料サービスを利用期間の途中で解約することもできません。</li>
      </ol>

      <h2>第12条（当社に対する補償）</h2>
      <p>利用者は、利用者が法令または本規約に違反して本サービスを利用したことで、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含むがこの限りではありません）を被った場合、当社の請求にしたがって直ちに損害を賠償しなければなりません。</p>

      <h2>第13条（利用者のデータおよびコンテンツの取扱い）</h2>
      <ol>
        <li>当社が本サービスの保守や改良などを必要と判断した場合には、当社は利用者が当社の管理するサーバーに保存しているデータを、本必要な範囲で複製等することができるものとします。
        </li>
        <li>利用者は、当社に対して、利用者が公開したコンテンツに関して、日本の国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含みます）する権利を期限の定めなく許諾（サブライセンス権を含みます）したものとみなします。また、利用者は著作者人格権を行使しないものとします。
        </li>
        <li>利用者が本サービスを利用して公開したコンテンツについての著作権を除き、本サービスおよび本サービスに関連する一切の情報についての著作権およびその他知的財産権はすべて当社または当社がその利用を許諾した権利者に帰属し、利用者は、無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます）、伝送、配布、出版、営業使用等をしてはならないものとします。
        </li>
        <li>当社は、利用者が公開したコンテンツの利用を、利用者自身を除く、他の利用者その他の第三者に許諾するものではなく、利用者は他の利用者が公開したコンテンツの権利を侵害してはならないものとします。また、利用者は、他の利用者が公開したコンテンツをクロール等で自動的に収集、解析する行為を行ってはならないものとします。
        </li>
        <li>当社は、他の利用者が公開したコンテンツを利用したことによって利用者に生じた損害について、一切の責任を負いません。また、利用者が他の利用者が公開したコンテンツを利用して利益を得た場合には、当社はその利益相当額の金員を請求できる権利を有するものとします。
        </li>
      </ol>

      <h2>第14条（連絡または通知）</h2>
      <ol>
        <li>利用者は、本規約に別段の定めがある場合を除き、当社への連絡は問い合わせフォームから行うものとします。当社は、電話による連絡および来訪は受け付けておりません。
        </li>
        <li>当社が利用者への連絡または通知の必要があると判断した場合には、登録情報をもとにした方法にて連絡または通知を行うものとし、利用者が提供した登録情報に誤り等があった場合であっても、当社は一切責任を負いません。
        </li>
      </ol>

      <h2>第15条（権利義務などの譲渡）</h2>
      <ol>
        <li>利用者は、本規約に基づくすべて地位、権利義務の全部または一部を、第三者に譲渡または貸与することはできません。
        </li>
        <li>当社が本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本規約上の地位、本規約に基づく権利および義務ならびに利用者の登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡について、予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他本サービスにかかる事業が移転するあらゆる場合を含むものとします。</li>
      </ol>

      <h2>第16条（プライバシー）</h2>
      <p>本サービスの利用にあたっては、当社のプライバシーポリシーも適用されます。利用者が当社に提供した登録情報は、当社のコンピューターに保存されます。利用者は、当社に対し、当社が利用者の興味や好み、閲覧のパターンについてのプロフィールを作成する等して、利用者の登録情報を利用することに同意するものとします｡</p>

      <h2>第17条（児童を誘引する行為の規制）</h2>
      <p>本サービスでは、インターネット異性紹介事業の利用に起因する児童買春その他の犯罪から児童を保護し、もって児童の健全な育成の目的のため、インターネット異性紹介事業を利用して児童を誘引する行為の規制を行っています。このため、当社では、本サービスの利用に当たって、利用者から身分証明書など予め定められた方法による年齢確認を行っております。また、自己紹介文の記入欄によって、年齢確認を行う前に他の利用者と出会う可能性を排除するため、自己紹介文の投稿監視を行っており、年齢確認前に自己紹介文に、他の利用者と出会うための要素（住所、日時等）が入っている場合は、当社は予告なく、自己紹介文の掲載をできないようにし、または、削除等する場合があり、利用者はこれに同意するものとします。</p>

      <h2>第18条（免責事項）</h2>
      <ol>
        <li>当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。なお、利用者との本規約に基づく本サービスの利用に関する契約が消費者契約法に定める消費者契約に該当する場合、上記の免責は適用されないものとし、当社は、当社の故意・重過失に起因する場合を除き、通常生じうる損害の範囲内で、かつ、有料サービスにおいては代金額（継続的なサービスの場合は1か月分相当額）を上限として損害賠償責任を負うものとします。
        </li>
        <li>本サービスの構成要素は、あらゆる種類の条件、保証、またはその他の契約条件なしに、無保証で提供されます。また、本サービスとの関係で効力を持った可能性のあるすべての説明、保証、条件、またはその他の契約条件（満足のゆく品質、目的適合性、合理的な配慮と技術の行使など、法によって暗示されている条件を含みますが、これに限られません）を法によって認められている最大限度まで排除するという前提で、当社は、利用者に対して本サービスを提供し、利用者もこの前提について同意したものとします。なお、排除することが不可能な強行法規の権利（消費者としての利用者の実定法上の権利を含みます）について、排除するものではありません。
        </li>
        <li>当社や当社のパートナー企業、利用者やその他の個人または団体が、本サービスを通じて表示し、アップロードし、または配布する助言、意見、プロフィール、声明やその他の情報について、当社はその正確さや信頼性を保証しません。上記助言、意見、プロフィール、声明やその他の情報を信頼することについては、利用者の自己責任になることを利用者は承認するものとします。
        </li>
        <li>オンラインでもオフラインでも、利用者の行動について当社は責任を負いません。本サービスを利用される際は、自己責任となります。
        </li>
        <li>本サービスは、日本国内においてのみ使用されることを想定しており、当社は、日本国外において本サービスの利用ができることを保証しません。また、当社は、日本国外における本サービスの利用について、一切のサポートを提供する義務を負わないものとします。</li>
      </ol>

      <h2>第19条（反社会的勢力の排除）</h2>
      <ol>
        <li>
          利用者は、次の各号のいずれにも該当しないことを表明し、将来にわたって次の各号のいずれにも該当しないことを確約するものとします。<br/>
          ①
          暴力団員（暴力団員による不当な行為の防止等に関する法律第２条第６号）、暴力団員でなくなった時から５年間を経過しない者、若しくはこれらに準ずる者、または暴力団若しくは暴力団員と密接な関係を有する者（以下、これらを個別にまたは総称して「暴力団員等」という。）であること<br/>
          ② 自らの行う事業に関し、暴力団員等の威力を利用し、財産上の不当な利益を図る目的で暴力団員等を利用し、または暴力団員等の威力を利用する目的で暴力団員等を従事させていると認められること<br/>
          ③ 自らが暴力団員等に対して資金を提供し、便宜を供与し、または不当に優先的に扱うなどの関与をしていると認められること<br/>
          ④ 本契約の履行が、暴力団員等の活動を助長し、または暴力団の運営に資するものであること<br/>
        </li>
        <li>
          当社は、利用者が次の各号の一に該当するときは、何らの通知、催告を要せず即時に本契約を解除することができる。<br/>
          ① 第１項に違反したとき<br/>
          ② 自らまたは第三者をして次に掲げる行為をしたとき<br/>
          ア 当社または他の会員その他の第三者に対する暴力的な要求行為<br/>
          イ 当社または他の会員その他の第三者に対する法的な責任を超えた不当な要求行為<br/>
          ウ 当社または他の会員その他の第三者に対する脅迫的言辞または暴力的行為<br/>
          エ 風説を流布し、または偽計若しくは威力を用いて、相手方の信用を毀損し、または当社または他の会員その他の第三者の業務を妨害する行為<br/>
          オ その他前記に準ずる行為
        </li>
        <li>当社は、第２項の規定により本契約を解除した場合、利用者に損害が生じても、これを賠償する責を負わないものとします。</li>
      </ol>

      <h2>第20条（本サービスのご利用上の注意）</h2>
      <ol>
        <li>本サービスは、パートナーを探している満18歳以上の利用者に対して、パートナー探しをサポートするサービスですが、不特定多数の者が利用するため、場合によっては本サービスを悪用される可能性があります。悪用される可能性があることを理解したうえで、他の利用者（特にメッセージのやり取りを行う他の利用者）との接し方（やりとり方法、個人情報の開示）を、慎重に考えて、本サービスを利用してください。また、やり取りをしている利用者が満18歳以上か否かは、利用者から提供した自己申告に基づく登録情報をもとに、当社が合理的に判断しています。このため、全ての利用者が満18歳以上であることを保証するものではありません。</li>
        <li>他の利用者に対して本サービス以外で接する場合には、慎重に検討し自己責任となることを理解したうえで行ってください。</li>
        <li>本規約に違反する疑いのある利用者を発見した場合、すみやかに、当社に報告してください。</li>
      </ol>

      <h2>第21条（本サービス及び利用規約の変更について）</h2>
      <ol>
        <li>当社が必要と判断した場合には、利用者の事前の承諾を得ることなく、関係法令に従ったうえで、本規約をいつでも変更することができるものとします。なお、改訂に際しては電子メール等で随時、利用者に告知するものとします。</li>
        <li>本規約の変更後に、利用者が本サービスを利用する場合には、変更後の本規約の内容を承諾したものとみなします。</li>
        <li>当社は、理由の如何を問わず、また、利用者に事前に通知することなく、本サービスの内容の全部または一部を変更、追加または中止することができるものとし、利用者は予めこれを承諾するものとします。</li>
      </ol>

      <h2>第22条（準拠法）</h2>
      <p>本規約は、日本法に準拠し、解釈されるものとします。</p>

      <h2>第23条（管轄裁判所）</h2>
      <p>利用者と当社との間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

      <h2>第24条（附則）</h2>
      <p>2020年10月22日 制定・施行</p>
    </section>
  )
}

export default TermsOfService;