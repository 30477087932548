import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

interface ButtonProps {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  size?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 30,
      color: '#fff',
      fontSize: 16,
      height: 60,
      maxWidth: 256,
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    medium: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      fontSize: 14,
      height: 36,
      maxWidth: 128,
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

const PrimaryButton = (props: ButtonProps) => {
  const classes = useStyles();
  const classNames = props.size === 'medium' ? classes.medium : classes.default;

  return (
    <Button className={classNames} disabled={props.disabled} variant="contained" onClick={() => props.onClick()}>
      {props.label}
    </Button>
  );
};

export default PrimaryButton;
