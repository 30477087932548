import React from 'react';
import { Loading, ScrollToTop } from 'components/UIkit';
import Router from 'Router';

const App = () => {
  return (
    <Loading>
      <ScrollToTop />
      <Router />
    </Loading>
  );
};

export default App;
