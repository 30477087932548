import React, {FC} from 'react';
import styles from 'assets/css/app.module.css';
import media from 'assets/css/media.module.css';
import {Spacer} from "components/UIkit";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

const imagePath = `${process.env.PUBLIC_URL}/usage`;

const Usage: FC = () => {
  const dispatch = useDispatch()

  return (
    <section className={styles.section}>
      <div className={media.rectangle}>
        <img src={`${imagePath}/usage.png`} alt={"アプリの使い方"} />
      </div>
      <Spacer size={"medium"} />
      <div className={styles.main}>
        <div className={styles.steps}>
          <a href="#step1">
            <span className={styles.label}>STEP1</span>
            <p className={styles.heading}>プロフィ<br/>ール設定</p>
            <span className={styles.triangle} />
          </a>
          <a href="#step2">
            <span className={styles.label}>STEP2</span>
            <p className={styles.heading}>お相手<br/>探し</p>
            <span className={styles.triangle} />
          </a>
          <a href="#step3">
            <span className={styles.label}>STEP3</span>
            <p className={styles.heading}>いいね!<br/>を送る</p>
            <span className={styles.triangle} />
          </a>
          <a href="#step4">
            <span className={styles.label}>STEP4</span>
            <p className={styles.heading}>マッチ<br/>成立!</p>
            <span className={styles.triangle} />
          </a>
          <a href="#step5">
            <span className={styles.label}>STEP5</span>
            <p className={styles.heading}>チャット<br/>開始</p>
            <span className={styles.triangle} />
          </a>
        </div>
        {/* STEP1 */}
        <Spacer size={"large"} />
        <span className={styles.step__label} id="step1">STEP1</span>
        <h3>プロフィール設定</h3>
        <p>
          プロフィール編集からプロフィール写真や自己紹介文、趣味などを設定しましょう。情報は何度でも変更可能です。
        </p>
        <Spacer size={"large"} />
        <img src={`${imagePath}/step1.png`} alt={"プロフィール設定"} height={251} width={336} />
        {/* STEP2 */}
        <Spacer size={"large"} />
        <span className={styles.step__label} id="step2">STEP2</span>
        <h3>お相手探し</h3>
        <p>
          メインメニュー「検索」から気になるお相手を探しましょう。検索条件を細かく設定でき、よりあなた好みのお相手を検索できます。
        </p>
        <Spacer size={"large"} />
        <img src={`${imagePath}/step2.png`} alt={"お相手探し"} height={379} width={336} />
        {/* STEP3 */}
        <Spacer size={"large"} />
        <span className={styles.step__label} id="step3">STEP3</span>
        <h3>いいね!を送る</h3>
        <p>
          気になるお相手を見つけたら「いいね！」ボタンをタップして、お相手に好意を伝えましょう。
        </p>
        <Spacer size={"large"} />
        <img src={`${imagePath}/step3.png`} alt={"いいね!を送る"} height={393} width={246} />
        {/* STEP4 */}
        <Spacer size={"large"} />
        <span className={styles.step__label} id="step4">STEP4</span>
        <h3>マッチ成立</h3>
        <p>
          お相手からも「いいね！」が届くとマッチ成立です。
          また、「いいね！」が自分に届いたら、「いいね！」を送り返してみましょう。
          お互いが「いいね！」をするとマッチ成立です。
        </p>
        <Spacer size={"large"} />
        <img src={`${imagePath}/step4.png`} alt={"マッチ成立"} height={132} width={417} />
        {/* STEP5 */}
        <Spacer size={"large"} />
        <span className={styles.step__label} id="step5">STEP5</span>
        <h3>チャット開始</h3>
        <p>
          マッチングが成立したお相手にメッセージを送って、仲を深めましょう。
        </p>
        <Spacer size={"large"} />
        <img src={`${imagePath}/step5.png`} alt={"チャット開始"} height={324} width={336} />
        {/* 8の取り組み */}
        <Spacer size={"large"} />
        <a role={"button"} onClick={() => dispatch(push('/efforts'))}>
          <img src={`${imagePath}/8efforts.png`} alt={"COCOLの8の取り組み"} height={80} width={335}/>
        </a>
        <Spacer size={"large"} />
      </div>
    </section>
  );
};

export default Usage;