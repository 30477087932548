import React, {FC} from 'react';
import styles from 'assets/css/content.module.css'

const PrivacyPolicy: FC = () => {
  return (
    <section className={styles.content}>
      <h1>プライバシーポリシー</h1>

      <p>株式会社LINKS(以下「当社」といいます)は、個人情報が個人の重要な財産であることを認識し、個人情報を適切に取得・利用し、個人情報に関する法令等を遵守し、個人情報の漏えい等を防止・是正するため、以下のとおり個人情報の取扱方針を定め、最新の注意を払って管理運用します。なお、リンク先など他事業者等による個人情報収集は、当プライバシーポリシーの適用範囲ではありません。</p>

      <h2>第1条(適切な取得と利用目的)</h2>
      <p>当社は、適法な手段により、以下の利用目的のため、個人情報を取得します。</p>
        <ul>
            <li>当社が提供するサービスの利用者（以下「利用者」といいます）の年齢確認や本人確認、住所確認を行い、必要に応じて、利用者への連絡をとって、当社サービスを十分に提供するため</li>
            <li>利用者の興味や好み、閲覧のパターンについてのプロフィールを作成し、他の利用者とのマッチングを提案し、また、利用者を他の利用者に対してマッチング相手として提案するため</li>
            <li>当社が現在提供しているサービス及び今後提供を検討しているサービス、あるいは、当社以外の第三者が広告主となる広告情報を案内し、アンケートを実施し、または、キャンペーン等に関するメールマガジンを送信するため</li>
            <li>利用者のニーズの調査、マーケティングデータの作成、統計、分析のため</li>
            <li>サービスの改善や新サービスの開発等に役立てるため</li>
            <li>利用規約に違反する態様での当社提供サービスの利用を防止するため</li>
            <li>その他、利用者の同意を得た目的のため</li>
        </ul>

      <h2>第2条(適正な管理と保護)</h2>
      <p>当社は、個人情報の管理は、厳重に行うこととし、個人情報に関する不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な予防及び是正処置を行います。</p>

      <h2>第3条(預託)</h2>
      <p>当社は利用目的の達成に必要な範囲内において、取得した個人情報の全部又は一部を業務委託先に預託することがあります。その際、業務委託先としての適格性を十分審査するとともに、個人情報の保護や守秘義務に関する契約を締結し、業務委託先に対し必要かつ適切な監督を行います。</p>

      <h2>第4条(第三者提供)</h2>
      <p>当社は、以下の場合を除き、第三者に対しデータを開示・提供しません。</p>
        <ul>
            <li>利用者が事前に同意した場合</li>
            <li>法令に基づく場合</li>
            <li>当社サービスの利用規約違反があり、当社の権利、財産やサービスなどを保護するために、個人情報を公開せざるをえないと判断した場合</li>
            <li>人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難な場合</li>
            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難な場合</li>
            <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
            <li>合併、会社分割、営業譲渡その他の事由によって個人情報を含む当社が保有する情報を提供する必要がある場合</li>
            <li>その他、前記に準じる場合</li>
        </ul>

      <h2>第5条(共同利用)</h2>
      <p>当社は、当社サービスの提供にあたり、当社の業務提携先と個人情報を共同利用することがあります。この場合、当社は、共同利用に先立ち、共同して利用する個人情報の項目、共同利用者の範囲、共同利用する個人情報の利用目的、共同利用する個人情報の管理責任者の氏名又は名称についてあらかじめ公表いたします。</p>

      <h2>第6条(開示等の手続)</h2>
        <ol>
            <li>当社は、利用者本人からの個人情報の開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者提供の停止（以下「開示等」といいます）の要請がなされた場合、遅滞なく応じます。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。</li>
            <li>開示等の請求は当社所定の手続きにて行っていただく必要があります。なお、個人情報の開示につきましては、当社所定の手数料が必要となります。</li>
        </ol>

      <h2>第7条(免責)</h2>
      <p>当社は、利用者本人が当社サービスの機能その他手段を用いて第三者に個人情報を明らかにした場合、利用者本人が当社サービス上で開示した情報等により個人を識別されてしまったとしても、何ら責任を負わないものとします。</p>

      <h2>第8条(統計データの作成及び利用)</h2>
      <p>当社は、取得した個人情報をもとに個人を識別できないように加工した統計データを作成することがあります。</p>

      <h2>第9条(属性情報・行動履歴等の取得・利用)</h2>
      <p>当社は、当社サービスや広告内容を利用者に適したものとするために、クッキー、ログ、アクセスしたURL、コンテンツ、参照順などの行動履歴、端末の個体識別情報などの機器情報、本人の性別、職業、年齢などの属性情報等を個人の識別ができない情報としたうえで、取得・利用を行うことがあります。また、同様の目的で広告主や情報提供元、サービス提供元などにかかる情報を提供する場合があります。</p>

      <h2>第10条(改定)</h2>
      <p>当社は、当プライバシーポリシーの全部又は一部を改定することがあります。重要な変更をする場合には、改定内容を当社ウェブサイト等で告知します。</p>

      <h2>第11条(法令、規範の遵守と見直し)</h2>
      <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本個人情報保護方針の内容を適宜見直し、その改善に努めます。</p>

      <h2>第12条(問い合わせ先)</h2>
      <p>当社は、利用者本人からの個人情報の取扱いに関する問い合わせに対し、適切な対応に努めます。当社の個人情報の取扱いに関する問い合わせは、下記にお願いします。</p>

      <p>連絡先 <a href={"mailto:info@cocol.tokyo"} target={"_blank"} rel="noopener noreferrer">info@cocol.tokyo</a></p>

    </section>
  );
};

export default PrivacyPolicy;