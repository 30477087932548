import React from 'react';
import {Theme} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/styles';
import {Spacer} from "components/UIkit";
import PaymentAuth from "components/Payment/PaymentAuth";
import {CheckoutWrapper} from "../components/Payment";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: '0 auto',
      maxWidth: 450,
      padding: 0,
      textAlign: 'center',
      width: '100%'
    },
    notes: {
      background: "#E5E5E5",
      padding: 16,
      textAlign: 'left',
      '& > h3': {
        fontSize: 16,
        fontWeight: 600
      },
      '& > p': {
        fontSize: 14
      }
    },
    difference: {
      background: "#E5E5E5",
      padding: '32px 16px',
      textAlign: 'center',
      '& > h3': {
        fontSize: 16,
        fontWeight: 600
      },
      '& > p': {
        background: '#53B5B0',
        borderRadius: 4,
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 1,
        padding: '4px 8px',
        textAlign: 'left',
        width: '100%'
      }
    },
    table: {
      borderTop: '1px solid #999999',
      borderLeft: '1px solid #999999',
      borderCollapse: 'collapse',
      width: '100%',
      '& th': {
        borderRight: '1px solid #999999',
        borderBottom: '1px solid #999999',
        fontWeight: 600,
        textAlign: 'center',
        padding: 8,
        width: 126,
        '&:nth-child(1)': {
          fontSize: 14
        },
        '&:nth-child(2)': {
          background: '#fff',
          fontSize: 14
        },
        '&:nth-child(3)': {
          background: '#53B5B0',
          color: '#fff',
          fontSize: 14,
        },
      },
      '& td': {
        borderRight: '1px solid #999999',
        borderBottom: '1px solid #999999',
        padding: 8,
        textAlign: 'center',
        width: 126,
        '&:nth-child(2)': {
          background: '#fff',
          fontSize: 12,
          fontWeight: 600
        },
        '&:nth-child(3)': {
          background: '#E6FFFE',
          color: '#53B5B0',
          fontSize: 16,
          fontWeight: 600
        },
      }
    },
    features: {
      padding: 16,
      '& > h3': {
        fontSize: 18,
        fontWeight: 600,
        textAlign: 'center'
      },
      '& > p': {
        fontSize: 16,
        textAlign: 'left'
      }
    },
    textPrimary: {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    textSecondary: {
      color: theme.palette.secondary.main,
      fontWeight: 600
    }
  })
);

const imagePath = `${process.env.PUBLIC_URL}/payment`;

const Payment = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  return (
    <PaymentAuth>
      <section className={classes.section}>
        <img src={`${imagePath}/thumbnail.png`} alt={"COCOLで始まるプレシャスライフ"} />
        <Spacer size={"medium"}/>
        <img src={`${imagePath}/hero_text.png`} alt={"COCOLなら3日間無料トライアルまずは、お試しで楽しんでみよう！"} height={109} width={312} />
        <Spacer size={"medium"}/>
        <CheckoutWrapper />
        <Spacer size={"medium"}/>
        <div className={classes.difference}>
          <h3>無料会員と有料会員の違い</h3>
          <Spacer size={"small"}/>
          <table className={classes.table}>
            <thead>
              <tr>
                <th />
                <th>無料会員</th>
                <th>有料会員</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <th>メッセージ</th>
              <td>1通目のみ<br/>送信可能</td>
              <td>無制限</td>
            </tr>
            <tr>
              <th>女性会員の<br/>いいね数</th>
              <td>非表示</td>
              <td>表示される</td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>登録時に<br/>10ポイント付与</td>
              <td>毎月<br/>50ポイント<br/>プレゼント</td>
            </tr>
            <tr>
              <th>既読表示</th>
              <td>非表示</td>
              <td>表示される</td>
            </tr>
            <tr>
              <th>足あと</th>
              <td>非表示</td>
              <td>表示される</td>
            </tr>
            <tr>
              <th>検索結果</th>
              <td>通常</td>
              <td>無料会員より<br/>上位に表示</td>
            </tr>
            </tbody>
          </table>
          <Spacer size={"xsmall"}/>
          <p>有料会員登録後、女性とのメッセージのやりとりがし放題になり、さまざまなメリットがあります</p>
        </div>
        <Spacer size={"medium"}/>
        <div className={classes.features}>
          <img src={`${imagePath}/cocol_features.png`} alt={"COCOLの特徴"} height={30} width={216} />
          <Spacer size={"small"}/>
          {/* No.1 */}
          <img src={`${imagePath}/no1.png`} alt={"特徴1"} height={63} width={50} />
          <Spacer size={"xsmall"}/>
          <h3>必ず好みの女性が見つかる</h3>
          <Spacer size={"small"}/>
          <p>
            累計登録会員のうち約75%が女性会員で、たくさんの容姿端麗な女性にご登録いただいております(2020年12月現在)。
            だから、いつでも自分好みの女性を見つけることができます。
          </p>
          <Spacer size={"medium"}/>
          {/* No.2 */}
          <img src={`${imagePath}/no2.png`} alt={"特徴2"} height={63} width={50} />
          <Spacer size={"xsmall"}/>
          <h3>必ず好みの女性が見つかる</h3>
          <Spacer size={"small"}/>
          <p>
            ご多忙の男性でもサクッと女性とお会いしていただけるように、女性と会うまでの時間の早さを徹底的に追求した機能や仕組みをご用意しております。
            ご登録いただいてから<span className={classes.textSecondary}>24時間以内のマッチング率が80%</span>を超え、
            さらに会いたい機能を使えば、最短即日で女性とお会いしていただけます。
          </p>
          <Spacer size={"medium"}/>
          {/* No.3 */}
          <img src={`${imagePath}/no3.png`} alt={"特徴3"} height={63} width={50} />
          <Spacer size={"xsmall"}/>
          <h3>必ず好みの女性が見つかる</h3>
          <Spacer size={"small"}/>
          <p>
            すべての会員様に安心安全にご利用していただくために、年齢確認はもちろん、プロフィール写真が本人かどうかを確認する本人確認なども実施しております。
            また、スクリーンショット検知により、マッチングアプリにご自身の写真をアップロードすることに抵抗がある方もご安心してご登録していただけます。
            その他、違反報告への迅速な対応をはじめとする
            <a className={classes.textPrimary} onClick={() => dispatch(push('/efforts'))}>
              「COCOLを安心安全にご利用いただくための8の取り組み」
            </a>
            を実施しております。
          </p>
        </div>
        <Spacer size={"medium"}/>
        <CheckoutWrapper />
        <Spacer size={"medium"}/>
        <div className={classes.notes}>
          <h3>注意事項</h3>
          <Spacer size={"xsmall"}/>
          <p>
            ・更新日の考え方は「翌月の同日で1ヵ月」となります。<br/>
            ・初月特別プランは72時間の無料体験期間終了後、自動課金されます。<br/>
            ・初月特別プランは自動更新されず、課金開始から1ヶ月後に停止します。有料期間を継続したい場合は、複数月プランをご購入ください。<br/>
            ・複数月プランのお支払いは一括払いとなります。分割でのお支払いは受け付けておりません。<br/>
            &nbsp;1ヶ月プラン：¥5,900（税込）<br/>
            &nbsp;3ヶ月プラン：¥16,400（税込）<br/>
            &nbsp;6ヶ月プラン：¥26,800（税込）<br/>
            &nbsp;12ヶ月プラン：¥38,800（税込）<br/>
            ・更新日前に解約のお手続きをなさった場合、残りの期間についての日割りでのご返金はいたしません。<br/>
            ・更新日前に解約のお手続きをなさった場合でも、残りの期間は有料プランの機能をご利用になれます。<br/>
            ・自動継続課金は期限が切れると自動的に課金される仕組みです。<br/>
            ・自動継続課金は期限が切れる前に設定からキャンセルにしなければ自動的に更新される仕組みです。<br/>
            ・自動継続課金の解約は、当ページから「有料会員の解約(自動更新の停止)」を行ってください。<br/>
            ・有料プランご契約の方は、特定期間のみの解約はできません。
          </p>
        </div>
      </section>
    </PaymentAuth>
  );
};

export default Payment;
