import React, { FC } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
    box: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      margin: '0 auto 4px auto',
      padding: 4,
      width: '100%',
      '& span': {
        fontSize: 16,
        padding: 2,
      },
    },
  })
);

interface CheckBoxesProps {
  boxes: { id: string; label: string }[];
  disabled: boolean;
  options: string[];
  onClick: (id: string) => void;
}

const Checkboxes: FC<CheckBoxesProps> = ({ boxes, disabled, options, onClick }) => {
  const classes = useStyles();

  return (
    <FormGroup className={classes.root} row>
      {boxes.map((box) => (
        <FormControlLabel
          className={classes.box}
          control={
            <Checkbox
              color={'secondary'}
              checked={options.includes(box.id)}
              disabled={disabled}
              onClick={() => onClick(box.id)}
              name={box.id}
            />
          }
          label={box.label}
          key={box.id}
        />
      ))}
    </FormGroup>
  );
};

export default Checkboxes;
