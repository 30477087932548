import React, {FC, useCallback, useEffect, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {GreyButton, ModalDefault, PrimaryButton, Spacer, TextDetail, WidthSpacer} from "../UIkit";
import {useDispatch, useSelector} from "react-redux";
import {subscribePaidPlan, unsubscribePaidPlan} from "reducks/payment/operations";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {State} from "index";
import {
  getIsPaidFirst, getIsPaidMember,
  getPaymentUid,
  getStripeCard,
  getStripeCustomerId, getStripeId,
  getStripePaymentMethodId
} from "reducks/payment/selectors";
import {SubscriptionPlan} from "reducks/payment/types";
import utility from 'assets/css/utility.module.css';

const imagePath = `${process.env.PUBLIC_URL}/payment`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 16px'
    },
    form: {
      background: theme.palette.grey[100],
      borderRadius: 4,
      color: theme.palette.grey[700],
      padding: 16,
      width: '100%'
    },
    buttonArea: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
    },
    paragraph: {
      color: "#5F5F5F",
      fontSize: 12
    },
    description: {
      color: "#5F5F5F",
      fontSize: 12,
      textAlign: 'left'
    },
    pointer: {
      cursor: 'pointer'
    },
    modalContent: {
      padding: 16,
      minWidth: 320,
      textAlign: 'center'
    },
    planRow: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center'
    }
  })
);

const CheckoutForm: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selector = useSelector((state: State) => state)

  const isPaidFirst = getIsPaidFirst(selector)
  const isPaidMember = getIsPaidMember(selector)
  const stripeCard = getStripeCard(selector)
  const stripeCustomerId = getStripeCustomerId(selector)
  const stripeId = getStripeId(selector)
  const stripePaymentMethodId = getStripePaymentMethodId(selector)
  const uid = getPaymentUid(selector)

  const stripe = useStripe();
  const elements = useElements();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [plan, setPlan] = useState<SubscriptionPlan>('');

  useEffect(() => {
    if (!isPaidFirst) {
      setPlan('first_month')
    } else if (!isPaidMember) {
      setPlan('3month')
    }
  },[isPaidFirst, isPaidMember])

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  },[setModalOpen])

  const handleModalOpen = useCallback(() => {
    setModalOpen(true)
  },[setModalOpen])

  const memberStatus = (() => {
    if (isPaidMember && stripeId !== "") {
      return "有料会員"
    } else if (isPaidMember && stripeId === "") {
      return "有料会員(自動更新停止済み)"
    } else {
      return "無料会員"
    }
  })()

  return (
    <div className={classes.root}>
      <p className={classes.paragraph}>
        現在の会員ステータス: {memberStatus}
      </p>
      <Spacer size={"small"}/>
      {/* 無料会員で一度もサブスクリプションを購入していない人には初月限定プランを表示 */}
      {(!isPaidFirst && !isPaidMember && stripeId === "") && (
        <div>
          <p className={classes.paragraph}>初月限定！3日間無料でお試し+2,900円</p>
          <Spacer size={"xsmall"}/>
          <img
            className={classes.pointer}
            src={`${imagePath}/trial_btn.png`}
            alt={"3日間無料トライアル"}
            height={109}
            width={312}
            onClick={() => handleModalOpen()}
          />
          <Spacer size={"xsmall"}/>
          <p className={classes.paragraph}>3日間の体験期間終了後、自動課金されます。</p>
        </div>
      )}
      {/* 初月限定プランを終えた人には4つのプランから選択させる */}
      {(isPaidFirst && !isPaidMember && stripeId === "") && (
        <>
          <div className={classes.planRow}>
            {plan === '1month' ? (
              <img
                className={classes.pointer}
                src={`${imagePath}/1month_primary.png`}
                alt={"1ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('1month')}
              />
            ) : (
              <img
                className={classes.pointer}
                src={`${imagePath}/1month_grey.png`}
                alt={"1ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('1month')}
              />
            )}
            <WidthSpacer size={"small"}/>
            {plan === '3month' ? (
              <img
                className={classes.pointer}
                src={`${imagePath}/3month_primary.png`}
                alt={"3ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('3month')}
              />
            ) : (
              <img
                className={classes.pointer}
                src={`${imagePath}/3month_grey.png`}
                alt={"3ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('3month')}
              />
            )}
            <WidthSpacer size={"small"}/>
            {plan === '6month' ? (
              <img
                className={classes.pointer}
                src={`${imagePath}/6month_primary.png`}
                alt={"6ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('6month')}
              />
            ) : (
              <img
                className={classes.pointer}
                src={`${imagePath}/6month_grey.png`}
                alt={"6ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('6month')}
              />
            )}
            <WidthSpacer size={"small"}/>
            {plan === '12month' ? (
              <img
                className={classes.pointer}
                src={`${imagePath}/12month_primary.png`}
                alt={"12ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('12month')}
              />
            ) : (
              <img
                className={classes.pointer}
                src={`${imagePath}/12month_grey.png`}
                alt={"12ヶ月更新プラン"}
                height={160} width={80}
                onClick={() => setPlan('12month')}
              />
            )}
          </div>
          <Spacer size={"small"}/>
          <img
            className={classes.pointer}
            src={`${imagePath}/purchase_btn.png`}
            alt={"有料会員になる"}
            height={109}
            width={312}
            onClick={() => handleModalOpen()}
          />
          <Spacer size={"xsmall"}/>
        </>
      )}
      {/*　有料会員には定期購読のキャンセルボタンを表示　*/}
      {(isPaidFirst && isPaidMember && stripeId !== "") && (
        <div>
          <p className={classes.description}>
            更新日前に解約のお手続きをなさった場合、残りの期間についての日割りでのご返金はいたしません。<br/>
            更新日前に解約のお手続きをなさった場合でも、残りの期間は有料プランの機能をご利用になれます。
          </p>
          <Spacer size={"small"}/>
          <GreyButton
            label={"有料会員を解約する"}
            onClick={() => dispatch(unsubscribePaidPlan(stripeId, uid))}
          />
        </div>
      )}
      <Spacer size={"medium"}/>
      <ModalDefault open={modalOpen} handleModalClose={handleModalClose}>
        <div className={classes.modalContent}>
          {(stripeCard.brand !== "" && stripeCard.last4 !== "") ? (
            <>
              <h3 className={utility.subTitle}>登録済みのカード情報</h3>
              <Spacer size={"xsmall"}/>
              <p>カード会社: {stripeCard.brand.toUpperCase()}</p>
              <p>カード番号: **** **** **** {stripeCard.last4}</p>
            </>
          ) : (
            <>
              <h3 className={utility.subTitle}>カード情報の入力</h3>
              <Spacer size={"xsmall"}/>
              <CardElement className={classes.form} />
            </>
          )}
          <Spacer size={"medium"}/>
          <div className={classes.buttonArea}>
            <PrimaryButton
              label={"購入する"}
              onClick={() => {
                dispatch(subscribePaidPlan({
                  customerId: stripeCustomerId,
                  elements: elements,
                  paymentMethodId: stripePaymentMethodId,
                  stripe: stripe,
                  plan: plan,
                  uid: uid
                }));
                handleModalClose();
              }}
            />
            <Spacer size={"small"}/>
            <GreyButton
              label={"キャンセル"}
              onClick={() => handleModalClose()}
            />
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default CheckoutForm;