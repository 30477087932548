import React from 'react';
import { Route, Switch } from 'react-router';
import {
  Chatbot,
  Contact,
  Company,
  Efforts,
  Law,
  PaymentSignIn,
  Payment,
  PaymentPhoneSignIn,
  PrivacyPolicy,
  TermsOfService,
  Usage,
  Withdrawal,
} from './templates';

const Router = () => {

  return (
    <div>
      <Switch>
        <Route exact path="/" component={Usage} />
        <Route exact path="/company" component={Company} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/efforts" component={Efforts} />
        <Route exact path="/faq" component={Chatbot} />
        <Route exact path="/law" component={Law} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route exact path="/usage" component={Usage} />
        <Route exact path="/withdrawal" component={Withdrawal} />

        <Route exact path="/payment" component={Payment} />
        <Route exact path="/payment/signin" component={PaymentSignIn} />
        <Route exact path="/payment/signin/phone" component={PaymentPhoneSignIn} />
      </Switch>
    </div>
  );
};

export default Router;
