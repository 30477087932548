import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 'auto',
      padding: '32px 64px',
    },
  })
);

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  return <section className={classes.root}>{children}</section>;
};

export default Layout;
