import React, {FC} from 'react';
import styles from 'assets/css/app.module.css';
import media from 'assets/css/media.module.css';
import utility from 'assets/css/utility.module.css';
import {Spacer} from "components/UIkit";

const imagePath = `${process.env.PUBLIC_URL}/efforts`;

const Efforts: FC = () => {

  return (
    <section className={styles.section}>
      <div className={media.rectangle}>
        <img src={`${imagePath}/efforts.png`} alt={"8の取り組み"} />
      </div>
      <Spacer size={"medium"} />
      <div className={styles.main}>
        <img src={`${imagePath}/icon_with_logo.png`} alt={"COCOLでは"} height={30} width={198} />
        <h2>
          安心・安全にご利用いただくための<br className={utility.displayOnlySm} />
          8の取り組みを実施しています
        </h2>
        {/* 取り組み1 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no1.png`} alt={"取り組み1"} height={63} width={50} />
        <h3>ご利用には年齢確認が必須です</h3>
        <p>
          年齢認証には<b>運転免許</b>、<b>パスポート</b>など公的証明書が必要となっており、アカウントを偽って第三者が勝手に作れないように対策がされています。
          また、お客様の個人情報は個人情報保護法に基づき、厳格な管理·取扱いを行っております。
        </p>
        <Spacer size={"small"} />
        <div className={styles.row}>
          <div className={styles.column}>
            <img src={`${imagePath}/bad_man.png`} alt={"悪質な会員の排除"}/>
            <p>悪質な会員の排除</p>
          </div>
          <div className={styles.column}>
            <img src={`${imagePath}/quality.png`} alt={"コミュニティ品質の向上化"}/>
            <p>コミュニティ品質の向上化</p>
          </div>
          <div className={styles.column}>
            <img src={`${imagePath}/police.png`} alt={"警察に届け出済み"}/>
            <p>警察に届け出済み</p>
          </div>
        </div>
        {/* 取り組み2 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no2.png`} alt={"取り組み2"} height={63} width={50} />
        <h3>24時間365日の常時監視体制</h3>
        <p>
          24時間365日オペレーター常駐体制で24時間365日のテキスト・画像投稿等の厳正な監視を行い、会員の皆様に安心・安全なサービスをお届けいたします。
        </p>
        {/* 取り組み3 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no3.png`} alt={"取り組み3"} height={63} width={50} />
        <h3>本人確認によるなりすまし防止</h3>
        <p>
          COCOLでは、本人確認機能があります。本人確認を完了したユーザーのみが本人確認完了済みユーザーを検索することが可能です。
        </p>
        {/* 取り組み4 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no4.png`} alt={"取り組み4"} height={63} width={50} />
        <h3>プライベートモードで更に安心</h3>
        <p>
          プライベートモードを使うことで、不特定多数の異性からプロフィールを閲覧されることはありません。
          また自分がいいね！したお相手、またはマッチング中の異性のみがあなたのプロフィールを閲覧できるようになります。
        </p>
        {/* 取り組み5 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no5.png`} alt={"取り組み5"} height={63} width={50} />
        <h3>不審なユーザーを通報・排除</h3>
        <p>
          他会員からCOCOLの利用規約に反している通報があり、事実確認を行った結果悪質な違反と判断された場合にユーザーを退会し、排除します。<br/>
          また、出会い目的以外で、COCOL以外の他サービスに誘導したり勧誘を行う悪質ユーザーは、見つけ次第強制退会などの対応を行っています。
        </p>
        {/* 取り組み6 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no6.png`} alt={"取り組み6"} height={63} width={50} />
        <h3>信頼の証「資産・所得証明」</h3>
        <p>
          プロフィールの年収が事実である証明となる年収証明機能は、希望する男性ユーザーのみが利用できます。<br/>
          年収証明を行うことで、信頼度が高まり女性からのアプローチも増加します。
        </p>
        {/* 取り組み7 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no7.png`} alt={"取り組み7"} height={63} width={50} />
        <h3>さくらゼロ宣言</h3>
        <p>
          サクラ＝運営側が用意した偽物の会員を指しますが、COCOLでは男女ともにサクラ会員は存在しません。
          また、業者などの不正ユーザーの徹底排除にも取り組んでおります。
        </p>
        {/* 取り組み8 */}
        <Spacer size={"medium"} />
        <img src={`${imagePath}/no8.png`} alt={"取り組み8"} height={63} width={50} />
        <h3>ブロック・通報機能</h3>
        <p>
          違反報告を頂いた場合、すぐさま運営事務局にて事実確認の調査を行います。事実確認ができ次第、
          対象ユーザーに注意喚起や、悪質ユーザーの場合は強制退会処分などを行っています。<br/>
          一度強制退会になったユーザーは、二度とCOCOLにご登録頂くことはできません。<br/>
          アプリ内で不審なユーザーを見つけた場合は、ブロック機能をご利用頂くか、違反報告機能にて運営にお知らせください。
        </p>
      </div>
      <Spacer size={"large"} />
      <div className={styles.safety}>
        {/* 安心・安全にご利用いただくために */}
        <img src={`${imagePath}/light.png`} alt={"安心・安全にご利用いただくために"} height={69} width={76} />
        <h3>安心・安全にご利用いただくために</h3>
        <p>
          マッチングしたお相手が信頼できるまで詳細な個人情報は教えないようにしましょう。<br/>
          また、下記のような行動をとる、怪しいユーザーを見かけた際は、コンタクトを取るのをやめ、違反報告より運営事務局にお知らせください。
        </p>
        <Spacer size={"medium"} />
        <div className={styles.row}>
          <img src={`${imagePath}/invitation.png`} alt={"勧誘禁止"} height={70} width={70} />
          <p>初回から他のメッセージアプリやSNS、サイトに勧誘してくる</p>
        </div>
        <Spacer size={"small"} />
        <div className={styles.row}>
          <img src={`${imagePath}/privacy-invasion.png`} alt={"個人情報"} height={70} width={70} />
          <p>本名、電話番号、住所、口座情報など詳細な個人情報を聞いてくる</p>
        </div>
        <Spacer size={"small"} />
        <div className={styles.row}>
          <img src={`${imagePath}/multi.png`} alt={"マルチ商法"} height={70} width={70} />
          <p>マルチ商法や情報商材などの勧誘、宣伝などを行ってくる</p>
        </div>
      </div>
    </section>
  );
};

export default Efforts;