import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { State } from 'index';
import { getPaymentSignedIn } from 'reducks/payment/selectors';
import {listenPaymentAuthState} from "reducks/payment/operations";

const PaymentAuth: React.StatelessComponent<React.Props<{}>> = ({ children }: { children?: any }) => {
  const dispatch = useDispatch()
  const selector = useSelector((state: State) => state);

  const isSignedIn = getPaymentSignedIn(selector);

  useEffect(() => {
    if (!isSignedIn) {
      dispatch(listenPaymentAuthState())
    }
  },[])

  if (!isSignedIn) {
    return <></>;
  } else {
    return children;
  }
};

export default PaymentAuth;
