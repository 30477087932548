import React, { FC } from 'react';
import List from '@material-ui/core/List';
import { Chat } from 'components/Chatbot';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChatsType } from 'reducks/question/types';

const useStyles = makeStyles(() =>
  createStyles({
    chats: {
      height: '446px',
      padding: '0',
      overflow: 'auto',
    },
  })
);

interface Props {
  chats: ChatsType;
}

const Chats: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.chats} id={'scroll-area'}>
      {props.chats.map((chat) => {
        return <Chat text={chat.text} type={chat.type} key={chat.text} />;
      })}
    </List>
  );
};

export default Chats;
