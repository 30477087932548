import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Theme} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/styles';
import {GreyButton, PrimaryButton, Spacer, TextInput} from "../components/UIkit";
import {useStringChangeEvent} from "../lib/customHooks";
import {push} from "connected-react-router";
import {emailSignIn} from "reducks/payment/operations";
import utility from "assets/css/utility.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: '0 auto',
      maxWidth: 450,
      padding: '64px 16px',
      textAlign: 'left',
      width: '100%'
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      width: '100%'
    },
    description: {
      fontSize: 14,
    }
  })
);

const PaymentSignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const inputEmail = useStringChangeEvent(setEmail)
  const inputPassword = useStringChangeEvent(setPassword)

  return (
    <section className={classes.section}>
      <h2 className={utility.title}>メールアドレスでログイン</h2>
      <Spacer size={"small"}/>
      <p className={classes.description}>クレジットカードでの決済にはログインが必要です。</p>
      <Spacer size={"large"}/>
      <div className={classes.content}>
        <TextInput
          fullWidth={true}
          label={"メールアドレス"}
          multiline={false}
          onChange={inputEmail}
          placeholder={"メールアドレス"}
          required={true}
          rows={1}
          type={"email"}
          value={email}
        />
        <Spacer size={"small"}/>
        <TextInput
          fullWidth={true}
          label={"パスワード"}
          multiline={false}
          onChange={inputPassword}
          placeholder={"6~12文字 半角英数字"}
          required={true}
          rows={1}
          type={"password"}
          value={password}
        />
        <Spacer size={"large"}/>
        <PrimaryButton
          label={"ログイン"}
          onClick={() => dispatch(emailSignIn(email, password))}
        />
        <Spacer size={"medium"}/>
        <GreyButton
          label={"または電話番号でログイン"}
          onClick={() => dispatch(push('/payment/signin/phone'))}
        />
      </div>
    </section>
  );
};

export default PaymentSignIn;
