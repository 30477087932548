import React, { FC, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Checkboxes, PrimaryButton, SelectBox, Spacer, TextInput } from '../components/UIkit';
import { useCheckbox, useSelect, useStringChangeEvent } from 'lib/customHooks';
import { findWithNullCheck } from 'lib/customArrayMethods';
import { useDispatch } from 'react-redux';
import { sendWithdrawalForm } from 'reducks/withdrawal/operation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: '32px 16px',
      margin: '0 auto',
      maxWidth: 768,
      width: 'cacl(100% - 16px)',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 28,
        fontWeight: 600,
        textAlign: 'center',
      },
      '& h3': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        fontSize: 18,
        fontWeight: 600,
        padding: '4px 8px',
        textAlign: 'left',
      },
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        padding: '4px 8px',
        textAlign: 'left',
      },
    },
    buttonArea: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      margin: '0 auto',
    },
  })
);

const Withdrawal: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [authId, setAuthId] = useState<string>(''),
    [email, setEmail] = useState<string>(''),
    [agreements, setAgreements] = useState<Array<string>>([]),
    [loginType, setLoginType] = useState<string>('email'),
    [nickname, setNickName] = useState<string>(''),
    [request, setRequest] = useState<string>(''),
    [reason, setReason] = useState<string>('');

  const reasonTypes = useMemo(
    () => [
      { id: 'service', name: 'COCOLのサービス内容について' },
      { id: 'trouble', name: 'COCOLでのトラブルについて' },
      { id: 'login', name: 'COCOLへのログインについて' },
      { id: 'purchase', name: 'COCOL内での購入について' },
      { id: 'interview', name: 'COCOLへの取材依頼について' },
    ],
    []
  );

  const loginTypes = useMemo(
    () => [
      { id: 'email', name: 'メールアドレス' },
      { id: 'phone_number', name: '電話番号' },
    ],
    []
  );

  const agreementBoxes = useMemo(
    () => [
      { id: 'cancellation', label: 'COCOLの全ての有料サービスを解約しました。' },
      { id: 'refund', label: '期間の途中での解約・退会をした場合も、日割りでのご返金はいたしかねます。' },
      { id: 'takeover', label: '再登録しても過去のデータを引き継ぐことはできません。' },
    ],
    []
  );

  const handleAgreementsCheck = useCheckbox(agreements, setAgreements);
  const inputAuthId = useStringChangeEvent(setAuthId);
  const inputEmail = useStringChangeEvent(setEmail);
  const inputNickname = useStringChangeEvent(setNickName);
  const inputReason = useStringChangeEvent(setRequest);
  const selectLoginType = useSelect(setLoginType);
  const selectReason = useSelect(setReason);

  return (
    <section className={classes.section}>
      <p>
        COCOLをご利用いただき、誠にありがとうございます。
        <br />
        退会につきまして、以下注意点を必ずお読みください。
      </p>
      <Spacer size={'small'} />
      <h3>1. 有料会員様へ</h3>
      <p>
        COCOLの全ての有料サービスを解約した後に退会申請を行ってください。<br/>
        また、期間の途中での解約・退会をした場合も、日割りでのご返金はいたしかねます。
      </p>
      <Spacer size={'small'} />
      <h3>2. 過去データの引き継ぎ</h3>
      <p>再登録しても過去のデータを引き継ぐことはできません。</p>

      <Spacer size={'small'} />
      <p>
        以下の退会フォームをご入力ください。
        <br />
        ★の項目は必須項目です。
      </p>
      <Spacer size={'small'} />
      <TextInput
        fullWidth={true}
        label={'アプリ内のユーザー名'}
        multiline={false}
        onChange={inputNickname}
        placeholder={'例）山田 太郎'}
        required={true}
        rows={1}
        type={'text'}
        value={nickname}
      />
      <Spacer size={'small'} />
      <SelectBox
        label={'ログイン方法※'}
        options={loginTypes}
        required={true}
        select={selectLoginType}
        value={loginType}
      />
      <Spacer size={'xsmall'} />
      {loginType === 'email' ? (
        <TextInput
          fullWidth={true}
          label={'登録時のメールアドレス'}
          multiline={false}
          onChange={inputAuthId}
          placeholder={'例）example@cocol.tokyo'}
          required={true}
          rows={1}
          type={'email'}
          value={authId}
        />
      ) : (
        <>
          <TextInput
            fullWidth={true}
            label={'登録時の電話番号'}
            multiline={false}
            onChange={inputAuthId}
            placeholder={'例）090-1234-5678'}
            required={true}
            rows={1}
            type={'email'}
            value={authId}
          />
          <Spacer size={'xsmall'} />
          <TextInput
            fullWidth={true}
            label={'ご連絡用メールアドレス(退会処理のご確認メールをお送りします)'}
            multiline={false}
            onChange={inputEmail}
            placeholder={'例）example@cocol.tokyo'}
            required={true}
            rows={1}
            type={'email'}
            value={email}
          />
        </>
      )}
      <Spacer size={'small'} />
      <SelectBox label={'退会理由※'} options={reasonTypes} required={true} select={selectReason} value={reason} />
      <Spacer size={'xsmall'} />
      <p>COCOLに改善してほしいポイントや欲しかった機能、その他ご意見があれば教えてください。</p>
      <TextInput
        fullWidth={true}
        label={''}
        multiline={true}
        onChange={inputReason}
        required={false}
        rows={10}
        type={'text'}
        value={request}
      />
      <Spacer size={'small'} />
      <p>退会する場合、以下の項目をご確認いただき、全てにチェックを入れてください。</p>
      <Checkboxes boxes={agreementBoxes} disabled={false} options={agreements} onClick={handleAgreementsCheck} />
      <Spacer size={'small'} />
      <div className={classes.buttonArea}>
        <PrimaryButton
          label={'COCOLを退会する'}
          onClick={() => {
            dispatch(
              sendWithdrawalForm(
                {
                  auth_id: authId,
                  is_withdrawn: false,
                  email: email,
                  login_type: loginType,
                  nickname: nickname,
                  request: request,
                  reason_id: reason,
                  reason_content: findWithNullCheck(reasonTypes, 'id', reason, 'name', ''),
                  withdrawal_id: '',
                },
                agreements
              )
            );
          }}
        />
      </div>
    </section>
  );
};

export default Withdrawal;
