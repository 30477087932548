import React, {FC} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {createStyles, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 auto'
    },
  })
)

interface Props {
  open: boolean
  handleModalClose: () => void
}

const ModalDefault: FC<Props> = React.memo(({children, open, handleModalClose}) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      maxWidth={"md"}
      open={open}
      onClose={() => handleModalClose()}
    >
      {children}
    </Dialog>
  )
})

export default ModalDefault