import * as Actions from 'reducks/users/action'
import { initialState } from 'reducks/store/initialState'

export const UsersReducer = (state = initialState.users, action: any) => {
    switch (action.type) {
        case Actions.SIGN_IN:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.SIGN_OUT:
            return {
                ...initialState.users,
            }
        default:
            return state
    }
}
