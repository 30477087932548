import React from 'react';
import TextField from '@material-ui/core/TextField';

interface TextInputProps {
  disabled?: boolean;
  fullWidth: boolean;
  label: string;
  multiline: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required: boolean;
  rows: number;
  type: string;
  value: string;
}

const TextInput = (props: TextInputProps) => {
  return (
    <TextField
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      label={props.label}
      margin="dense"
      multiline={props.multiline}
      placeholder={props.placeholder}
      required={props.required}
      rows={props.rows}
      variant={'outlined'}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
    />
  );
};

export default TextInput;
