import { createSelector } from 'reselect';
import State from 'reducks/store/types';

const paymentSelector = (state: State) => state.payment;

export const getPaymentAuthId = createSelector([paymentSelector], (state) => state.auth_id);
export const getIsPaidFirst = createSelector([paymentSelector], (state) => state.isPaidFirst);
export const getIsPaidMember = createSelector([paymentSelector], (state) => state.isPaidMember);
export const getStripeId = createSelector([paymentSelector], (state) => state.stripeId);
export const getPaymentSignedIn = createSelector([paymentSelector], (state) => state.isSignedIn);
export const getStripeCard = createSelector([paymentSelector], (state) => state.stripeCard);
export const getStripeCustomerId = createSelector([paymentSelector], (state) => state.stripeCustomerId);
export const getStripePaymentMethodId = createSelector([paymentSelector], (state) => state.stripePaymentMethodId);
export const getPaymentUid = createSelector([paymentSelector], (state) => state.uid);

