import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { AnswerType } from 'reducks/question/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginBottom: 8,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  })
);

interface Props {
  answer: AnswerType;
  select: (content: string, nextId: string) => void;
}

const Answer: FC<Props> = (props) => {
  const { answer, select } = props;

  const classes = useStyles();

  return (
    <Button className={classes.button} variant="outlined" onClick={() => select(answer.content, answer.nextId)}>
      {answer.content}
    </Button>
  );
};

export default Answer;
