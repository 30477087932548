import {auth, userRef, BASE_URL, db, FirebaseTimestamp, pointRef} from 'firebase/index';
import { paymentSignInAction} from './action';
import { push } from 'connected-react-router';
import { isValidEmailFormat, isValidRequiredInput } from 'lib/commonFunc';
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/action';
import { Action, Dispatch } from 'redux';
import * as stripeJs from '@stripe/stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import {State} from "../../index";
import {Customer} from "../users/types";
import {SubscriptionPlan} from "./types";

const headers = new Headers()
headers.set('Content-type', 'application/json')

const retrievePaymentMethod = async (paymentMethodId: string) => {
  const response = await fetch(BASE_URL + '/api/v1/paymentMethod', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      paymentMethodId: paymentMethodId
    })
  });

  const cardResponse = await response.json();
  const paymentMethod = JSON.parse(cardResponse.body);
  return paymentMethod.card
}

export const emailSignIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<Action>, getState: () => State) => {
    dispatch(showLoadingAction());

    if (!isValidRequiredInput(email, password)) {
      dispatch(hideLoadingAction());
      alert('メールアドレスかパスワードが未入力です。');
      return false;
    }

    if (!isValidEmailFormat(email)) {
      dispatch(hideLoadingAction());
      alert('メールアドレスの形式が不正です。');
      return false;
    }

    return auth
      .signInWithEmailAndPassword(email, password)
      .then(async (result: firebase.auth.UserCredential) => {
        const userState = result.user;

        if (!userState) {
          dispatch(hideLoadingAction());
          throw new Error('ユーザーIDを取得できません');
        }
        const userId = userState.uid;

        return userRef.doc(userId).onSnapshot(async (snapshot) => {
          const data = snapshot.data() as Customer;
          if (!data) {
            throw new Error('ユーザーデータが存在しません');
          }

          let stripeCard = getState().payment.stripeCard

          if (data.stripe_payment_method_id && data.stripe_payment_method_id !== "") {
            stripeCard = await retrievePaymentMethod(data.stripe_payment_method_id)
          }

          const customerState = {
            auth_id: data.auth_id,
            nickname: data.nickname,
            isPaidFirst: data.is_paid_first ?? false,
            isPaidMember: data.is_paid_member ?? false,
            isSignedIn: true,
            stripeCard: stripeCard,
            stripeCustomerId: data.stripe_customer_id ?? "",
            stripeId: data.original_transaction_id ?? "",
            stripePaymentMethodId: data.stripe_payment_method_id ?? "",
            uid: userId
          };

          // Update logged in user state
          dispatch(paymentSignInAction(customerState));
          dispatch(hideLoadingAction());
          dispatch(push('/payment'));
        });
      })
      .catch(() => {
        dispatch(hideLoadingAction());
        alert('メールアドレスかパスワードが間違っています。');
        return false;
      });
  };
};


export const listenPaymentAuthState = () => {
  return async (dispatch: Dispatch<Action>, getState: () => State) => {
    dispatch(showLoadingAction())

    return auth.onAuthStateChanged((user) => {
      if (user) {
        userRef.doc(user.uid).onSnapshot(async (snapshot) => {
          const data = snapshot.data() as Customer;
          if (!data) {
            dispatch(hideLoadingAction())
            throw new Error('ユーザーデータが存在しません。');
          }

          let stripeCard = getState().payment.stripeCard

          if (data.stripe_payment_method_id && data.stripe_payment_method_id !== "") {
            stripeCard = await retrievePaymentMethod(data.stripe_payment_method_id)
          }

          const customerState = {
            auth_id: data.auth_id,
            nickname: data.nickname,
            isPaidFirst: data.is_paid_first ?? false,
            isPaidMember: data.is_paid_member ?? false,
            isSignedIn: true,
            stripeCard: stripeCard,
            stripeCustomerId: data.stripe_customer_id ?? "",
            stripeId: data.original_transaction_id ?? "",
            stripePaymentMethodId: data.stripe_payment_method_id ?? "",
            uid: user.uid
          };

          // Update logged in user state
          dispatch(paymentSignInAction(customerState));

          dispatch(hideLoadingAction());
          dispatch(push('/payment'));
        });
      } else {
        dispatch(hideLoadingAction())
        dispatch(push('/payment/signin'));
      }
    });
  };
};

// ===== Stripe ===== //
const alertCardError = (response: { paymentIntent?: stripeJs.PaymentIntent; error?: stripeJs.StripeError }): string => {
  if (response.error) {
    // Error code reference: https://stripe.com/docs/error-codes
    switch (response.error.code) {
      case 'card_declined':
        return 'カードによる支払いが拒否されました。ご利用のカード会社にお問い合わせください。'
      case 'parameter_missing':
        return '決済処理中に問題が発生しました。ご利用のカード会社にお問い合わせください。'
      default:
        if (response.error.message) {
          return '決済処理中に問題が発生しました。' + response.error.message
        } else {
          return '決済処理中に問題が発生しました。時間を置いてもう1度お試しいただくか、カード会社にお問い合わせください。'
        }
    }
  } else {
    return '決済処理中に問題が発生しました。'
  }
}

interface SubscriptionValues {
  customerId: string
  elements: stripeJs.StripeElements | null
  stripe: stripeJs.Stripe | null
  paymentMethodId: string
  plan: SubscriptionPlan
  uid: string
}

export const subscribePaidPlan = (values: SubscriptionValues) => {
  return async (dispatch: Dispatch<Action>) => {
    const {customerId, elements, stripe, paymentMethodId, plan, uid} = values;
    let _customerId = customerId,
      _paymentMethodId = paymentMethodId;

    dispatch(showLoadingAction('決済処理中...'))
    try {
      //*********************** START VALIDATION **************************//
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      if (!stripe || !elements) throw new Error('Does not exist stripe or elements')

      // Use your card Element with other Stripe.js APIs
      if (_paymentMethodId === "") {
        // Get a reference to a mounted CardInputForm.
        const cardElement = elements.getElement(CardElement)
        if (!cardElement) throw new Error('Does not exist cardElement')

        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error) throw new Error(error.message)

        if (paymentMethod) {
          _paymentMethodId = paymentMethod.id
        }
      }
      //*********************** END VALIDATION **************************//

      if (_customerId === "") {
        // Create customer on Stripe and register the email.
        const createCustomer = await fetch(BASE_URL + '/api/v1/customer', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            paymentMethod: _paymentMethodId,
            userId: uid,
          }),
        })

        const customerResponse = await createCustomer.json()
        const customerData = JSON.parse(customerResponse.body)
        _customerId = customerData.id
      }

      // Create subscription plan
      const url = plan === 'first_month' ? BASE_URL + '/api/v1/firstSubscription' : BASE_URL + '/api/v1/subscription';

      const createSubscription = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          customerId: _customerId,
          plan: plan,
          paymentMethodId: _paymentMethodId,
          uid: uid
        }),
      })

      const subscriptionResponse = await createSubscription.json()
      const subscriptionData = JSON.parse(subscriptionResponse.body)

      if (!subscriptionData) {
        throw new Error("Failed to subscribe the plan")
      }

      const batch = db.batch()
      const now = FirebaseTimestamp.now()
      const ref = pointRef.doc()

      const pointJson = {
        created_at: now,
        point: 50,
        type: "付与",
        updated_at: now,
        user_id: uid
      }

      const userJson = {
        is_paid_first: true,
        is_paid_member: true,
        stripe_customer_id: _customerId,
        stripe_payment_method_id: _paymentMethodId,
        updated_at: now
      }

      batch.update(userRef.doc(uid), userJson)
      batch.set(pointRef.doc(ref.id), pointJson)

      batch.commit()
        .then(() => {
          dispatch(hideLoadingAction())
          alert("決済が完了しました。アプリに戻って引き続きお楽しみください。")
        })
        .catch((error) => {
          throw new Error(error)
        })

    } catch (e) {
      console.log(e)
      dispatch(hideLoadingAction())
      alert("決済処理が失敗しました。通信状況を確認して再度お試しください。")
      return
    }
  }
}

export const unsubscribePaidPlan = (stripeId: string, uid: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const ret = window.confirm('有料会員の自動更新を解約しますか？')

    if (!ret) {
      return false
    } else {
      dispatch(showLoadingAction())

      try {
        const unsubscribePlan = await fetch(BASE_URL + '/api/v1/unsubscription', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            subscriptionId: stripeId,
            userId: uid,
          }),
        })

        const unsubscribeResponse = await unsubscribePlan.json()
        const unsubscriptionData = JSON.parse(unsubscribeResponse.body)

        if (unsubscriptionData && unsubscriptionData.status === 'canceled') {
          const now = FirebaseTimestamp.now()

          return db.collection('user')
            .doc(uid)
            .update({original_transaction_id: "", updated_at: now})
            .then(() => {
              dispatch(hideLoadingAction())
              alert("有料会員の自動更新を解約しました。次回更新日まで有料会員としてお楽しみいただけます。")
              return true
            })
            .catch((error) => {
              throw new Error(error)
            })
        } else {
          dispatch(hideLoadingAction())
          alert("解約処理が失敗しました。通信状況を確認して再度お試しください。それでも解決しない場合、お手数ですが運営事務局へお問い合わせください。")
          return false
        }
      } catch (e) {
        console.log(e)
        dispatch(hideLoadingAction())
        alert("解約処理が失敗しました。通信状況を確認して再度お試しください。それでも解決しない場合、お手数ですが運営事務局へお問い合わせください。")
        return false
      }
    }
  }
}
