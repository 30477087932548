export const findWithNullCheck = (
  array: Array<any>,
  findKey: string,
  matchedKey: any,
  targetKey: string,
  initValue: any
) => {
  const target = array.find((item) => item[findKey] === matchedKey);

  if (target) {
    return target[targetKey];
  } else {
    return initValue;
  }
};
