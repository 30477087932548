import React, {FC} from 'react';
import styles from 'assets/css/content.module.css'

const Company: FC = () => {
  return (
    <section className={styles.content}>
      <h1>運営会社情報</h1>
      <p>〒860-0807</p>
      <p>熊本市中央区下通一丁目8番20号下通センタービル5階</p>
      <p>株式会社LINKS</p>
      <p>代表取締役　杉浦　健</p>
      <p>TEL: 096-247-6944</p>
    </section>
  );
};

export default Company;