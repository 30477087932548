import { storage } from 'firebase/index';

/**
 * When user select an image file from his local directory, upload it to Firebase Storage, get download URL,
 * and set the URL to the src property of img tag for displaying the thumbnail.
 * @param {string} id The identifier of input tag for uploading files
 * @param {string} type -> add or remove
 */
export const attachFiles = (id: string, type: string): void => {
  const doc = document.getElementById(id);

  if (doc) {
    if (type === 'remove') {
      return doc.removeEventListener('change', () => null);
    } else if (type === 'add') {
      doc.addEventListener('change', (event) => {
        // @ts-ignore
        const file = event.target.files;
        let blob = new Blob(file, { type: 'image/jpeg' });

        // Generate random 16 digits strings
        const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const N = 16;
        const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
          .map((n) => S[n % S.length])
          .join('');

        const uploadRef = storage.ref('images').child(fileName);
        const uploadTask = uploadRef.put(blob);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('Failed to upload file. ERROR: ', error);
          },
          () => {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              document.getElementById(`${id}-thumb`)!.setAttribute('src', downloadURL);
            });
          }
        );
      });
    }
  }
};

/**
 * Convert Carriage Return and Line Feed into <br> tag.
 * @param {string} text The row text
 * @returns {void | string | never} The formatted text
 */
export const brToReturnCode = (text: string): string => {
  if (text === '') {
    return text;
  } else {
    const result = text.replace(/<br\/>/g, '\r\n');
    return result;
  }
};

/**
 * Convert Carriage Return and Line Feed into <br> tag.
 * @param {string} text The row text
 * @returns {void | string | never} The formatted text
 */
export const returnCodeToBr = (text: string): string => {
  if (text === '') {
    return text;
  } else {
    const result = text.replace(/\r?\n/g, '<br/>');
    return result;
  }
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const dateToWeekday = (dt: Date): string => {
  const weekdays: Array<string> = ['日', '月', '火', '水', '木', '金', '土'];
  return weekdays[dt.getDay()];
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const dateToString = (dt: Date): string => {
  return dt.getFullYear() + '-' + ('00' + (dt.getMonth() + 1)).slice(-2) + '-' + ('00' + dt.getDate()).slice(-2);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const prevDateAsString = (dt: Date): string => {
  const prevDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 1);
  return dateToString(prevDate);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const nextDateAsString = (dt: Date): string => {
  const nextDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 1);
  return dateToString(nextDate);
};

export const getFirstDateAsString = (dt: Date): string => {
  const firstDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
  return dateToString(firstDate);
};

export const getLastDateAsString = (dt: Date): string => {
  const lastDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
  return dateToString(lastDate);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const monthToString = (dt: Date): string => {
  return dt.getFullYear() + '-' + ('00' + (dt.getMonth() + 1)).slice(-2);
};

export const getPrevMonth = (dt: Date): Date => {
  return new Date(dt.getFullYear(), dt.getMonth() - 1);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const prevMonthAsString = (dt: Date): string => {
  const prevMonth = new Date(dt.getFullYear(), dt.getMonth() - 1);
  return monthToString(prevMonth);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const nextMonthAsString = (dt: Date): string => {
  const nextMonth = new Date(dt.getFullYear(), dt.getMonth() + 1);
  return monthToString(nextMonth);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const yearToString = (dt: Date): string => {
  return dt.getFullYear().toString();
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const prevYearAsString = (dt: Date): string => {
  const prevYear = new Date(dt.getFullYear() - 1, 0, 1);
  return yearToString(prevYear);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const nextYearAsString = (dt: Date): string => {
  const nextYear = new Date(dt.getFullYear() + 1, 0, 1);
  return yearToString(nextYear);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const getDateOnBusinessDay = (date: Date = new Date()): Date => {
  return new Date(date.getTime() - 1000 * 60 * 60 * 7 - 1000 * 60 * 59);
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const getWeekday = (date: Date): string => {
  const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
  return weekdays[date.getDay()];
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "YYYY-MM-DD"
 */
export const datetimeToString = (dt: Date) => {
  return (
    dt.getFullYear() +
    '-' +
    ('00' + (dt.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + dt.getDate()).slice(-2) +
    ' ' +
    ('00' + dt.getHours()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes()).slice(-2) +
    ':' +
    ('00' + dt.getSeconds()).slice(-2)
  );
};

/**
 * Convert datetime into the String.
 * @param {Date} dt
 * @returns {string} "HH:mm"
 */
export const timeToString = (dt: Date): string => {
  return ('00' + dt.getHours()).slice(-2) + ':' + ('00' + dt.getMinutes()).slice(-2);
};

export const timeSecondsToString = (dt: Date): string => {
  return (
    ('00' + dt.getHours()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes()).slice(-2) +
    ':' +
    ('00' + dt.getSeconds()).slice(-2)
  );
};

/**
 * Convert datetime into the String.
 * @param {firebase.firestore.Timestamp} timestamp
 * @returns {string} "YYYY-MM-DD"
 */
export const timestampToDateString = (timestamp: firebase.firestore.Timestamp): string => {
  // @ts-ignore
  const dt = new Date(timestamp._seconds * 1000);
  return dt.getFullYear() + '-' + ('00' + (dt.getMonth() + 1)).slice(-2) + '-' + ('00' + dt.getDate()).slice(-2);
};

/**
 * Convert datetime into the String.
 * @param {firebase.firestore.Timestamp} timestamp
 * @returns {string} "YYYY-MM-DD HH:mm:ss"
 */
export const timestampToDatetimeString = (timestamp: firebase.firestore.Timestamp): string => {
  // @ts-ignore
  const dt = new Date(timestamp._seconds * 1000);
  return (
    dt.getFullYear() +
    '-' +
    ('00' + (dt.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + dt.getDate()).slice(-2) +
    ' ' +
    ('00' + dt.getHours()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes()).slice(-2) +
    ':' +
    ('00' + dt.getSeconds()).slice(-2)
  );
};

/**
 * Convert datetime into the String.
 * @param {firebase.firestore.Timestamp} timestamp
 * @returns {string} "YYYY-MM-DD"
 */
export const timestampToTimeString = (timestamp: firebase.firestore.Timestamp): string => {
  // @ts-ignore
  const dt = new Date(timestamp._seconds * 1000);
  return ('00' + dt.getHours()).slice(-2) + ':' + ('00' + dt.getMinutes()).slice(-2);
};

/**
 * Convert datetime into the String.
 * @param {firebase.firestore.Timestamp} timestamp
 * @returns {string} "YYYY-MM-DD"
 */
export const timestampToTimeSecondString = (timestamp: firebase.firestore.Timestamp): string => {
  // @ts-ignore
  const dt = new Date(timestamp._seconds * 1000);
  return (
    ('00' + dt.getHours()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes()).slice(-2) +
    ':' +
    ('00' + dt.getSeconds()).slice(-2)
  );
};

/**
 * Validate input email
 * @param email
 * @returns {boolean}
 */
export const isValidEmailFormat = (email: string) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

/**
 * Show an alert if required input is blank
 * @param args Required input values
 * @returns {boolean}
 */
export const isValidRequiredInput = (...args: Array<any>) => {
  let validator = true;
  for (let i = 0; i < args.length; i = (i + 1) | 0) {
    if (args[i] === '') {
      validator = false;
    }
  }
  return validator;
};

export const isValidMinLength = (text: string, minLength: number) => {
  return text.length >= minLength;
};

export const isValidMaxLength = (text: string, maxLength: number) => {
  return text.length <= maxLength;
};

/**
 *
 * @param augend
 * @param addendMinutes
 * @return string Time as HH:mm
 */
export const sumTimeAsString = (augend: string, addendMinutes: number): string => {
  const augendHours = parseInt(augend.split(':')[0], 10);
  const augendMinutes = parseInt(augend.split(':')[1], 10);
  const totalMinutes = augendHours * 60 + augendMinutes + addendMinutes;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2);
};

export const calcPercentage = (divided: number, divisor: number, decimalPlace: number) => {
  const result = Math.floor((divided / divisor) * 100 * 10 ** decimalPlace) / 10 ** decimalPlace;
  if (isNaN(result)) {
    return 0;
  } else {
    return result;
  }
};

export const divideWithDecimalPoint = (divided: number, divisor: number, decimalPlace: number) => {
  const result = Math.floor((divided / divisor) * 10 ** decimalPlace) / 10 ** decimalPlace;
  if (isNaN(result)) {
    return 0;
  } else {
    return result;
  }
};
