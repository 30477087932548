import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { firebaseConfig } from 'firebase/config';

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const fb = firebase;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const userRef = db.collection('user');
export const withdrawUserRef = db.collection('withdraw_user');
export const contactRef = db.collection('contact');
export const notificationRef = db.collection('notification');
export const informationRef = db.collection('information');
export const pointRef = db.collection('point');
export const favoriteRef = db.collection('favorite');
export const paymentRef = db.collection('payment');
export const violationRef = db.collection('violation');
export const withdrawalRef = db.collection('withdrawal');
export const BASE_URL = process.env.REACT_APP_PROJECT_ID === 'cocol-prod' ? "https://cocol-admin.web.app" : "https://cocol-admin-dev.web.app";
