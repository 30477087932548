import { db, FirebaseTimestamp } from 'firebase/index';
import { ContactType } from 'reducks/contact/types';
import { Action, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { getMessage } from 'lib/messages';
import { isValidEmailFormat, isValidMaxLength, isValidMinLength } from '../../lib/commonFunc';
import { hideLoadingAction, showLoadingAction } from '../loading/action';

const contactRef = db.collection('contact');

const sendEmail = async (
  email: string,
  name: string,
  kana: string,
  companyName: string,
  phoneNumber: string,
  typeContent: string,
  description: string
) => {
  const headers = new Headers();
  headers.set('Content-type', 'application/json; charset=utf-8');

  const subject = '【COCOL】お問い合わせを受け付けました';
  const body = `
    <p>${name}様</p>
    <p>
      COCOL運営事務局です。<br/>
      いつもCOCOLをご利用いただき、誠にありがとうございます。
    </p>
    <p>
      先ほど、以下の内容でお問い合わせを受け付けました。<br/>
      担当者がお問い合わせ内容を確認した後、ご連絡いたします。
    </p>
    <p>
      お名前：${name}<br/>
      フリガナ：${kana}<br/>
      会社名：${companyName}<br/>
      メールアドレス：${email}<br/>
      電話番号：${phoneNumber}<br/>
      お問い合わせ種別：${typeContent}<br/>
      お問い合わせ内容：<br/>
      ${description}
    </p>
    <p>
      =============<br/>
      運営会社：株式会社LINKS<br/>
      その他のお問い合わせは<a href="https://cocol.tokyo/contact">コチラ</a>から<br/>
      =============      
    </p>
  `;

  return await fetch('https://z603oku7n4.execute-api.us-east-1.amazonaws.com/v1/mail', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: JSON.stringify({
      sendTo: email,
      subject: subject,
      body: body,
    }),
  });
};

export const sendForm = (value: ContactType) => {
  return async (dispatch: Dispatch<Action>) => {
    // Validations
    if (value.name === '') {
      alert(getMessage('2100'));
      return false;
    }
    if (value.kana === '') {
      alert(getMessage('2101'));
      return false;
    }
    if (value.email === '') {
      alert(getMessage('1300'));
      return false;
    }
    if (!isValidEmailFormat(value.email)) {
      alert(getMessage('1305'));
      return false;
    }
    if (value.phone_number !== '' && !isValidMinLength(value.phone_number, 10)) {
      alert(getMessage('1102'));
      return false;
    }
    if (value.phone_number !== '' && !isValidMaxLength(value.phone_number, 13)) {
      alert(getMessage('1103'));
      return false;
    }
    if (value.type_id === '') {
      alert(getMessage('2102'));
      return false;
    }
    if (value.description === '') {
      alert(getMessage('2103'));
      return false;
    }

    dispatch(showLoadingAction());
    const ref = contactRef.doc();
    const now = FirebaseTimestamp.now();

    value.contact_id = ref.id;
    value.created_at = now;
    value.updated_at = now;
    
    return contactRef
      .doc(value.contact_id)
      .set(value)
      .then(async () => {
        const res = await sendEmail(
          value.email,
          value.name,
          value.kana,
          value.company_name,
          value.phone_number,
          value.type_content,
          value.description
        );

        if (res.status === 200) {
          dispatch(hideLoadingAction());
          alert('お問い合わせ内容を送信しました。\n担当者からのご連絡をお待ちください。');
          return dispatch(push('/'));
        } else {
          dispatch(hideLoadingAction());
          alert('お問い合わせ内容の送信に失敗しました。\n通信環境をご確認いただき、もう1度お試しください。');
          return false;
        }
      })
      .catch((error) => {
        dispatch(hideLoadingAction());
        alert('お問い合わせ内容の送信に失敗しました。\n通信環境をご確認いただき、もう1度お試しください。');
        console.error(error);
        return false;
      });
  };
};
