import React, { FC } from 'react';
import styles from 'assets/css/spacer.module.css';

interface SpacerProps {
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
}

const WidthSpacer: FC<SpacerProps> = ({ size }) => {
  const spacerClass = 'widthSpacer-' + size;
  return <div className={styles[spacerClass]} />;
};

export default WidthSpacer;
