import React, { FC } from 'react';
import { Answer } from 'components/Chatbot';
import { AnswersType } from 'reducks/question/types';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    answers: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      height: 192,
    },
  })
);

interface Props {
  answers: AnswersType;
  select: (content: string, nextId: string) => void;
}

const AnswersList: FC<Props> = (props) => {
  const { answers, select } = props;
  const classes = useStyles();

  return (
    <div className={classes.answers}>
      {answers.map((answer) => {
        return <Answer answer={answer} key={answer.content} select={select} />;
      })}
    </div>
  );
};

export default AnswersList;
