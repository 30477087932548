import React, { useCallback, useEffect, useState, DependencyList, SetStateAction, Dispatch } from 'react';

export const useStringChangeEvent = (update: Dispatch<SetStateAction<string>>) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      update(event.target.value);
    },
    [update]
  );
};

export const useNumberChangeEvent = (update: Dispatch<SetStateAction<number>>) => {
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (/^[0-9]+$/.test(event.target.value)) {
        update(Number(event.target.value));
      }
    },
    [update]
  );
};

export const useSelect = (update: Dispatch<SetStateAction<string>>) => {
  return useCallback(
    (value: string) => {
      update(value);
    },
    [update]
  );
};

export const useCheckbox = (currentState: string[], update: Dispatch<SetStateAction<string[]>>) => {
  return useCallback(
    (id: string) => {
      if (currentState.some((state) => state === id)) {
        const nextState = currentState.filter((state) => state !== id);
        update([...nextState]);
      } else {
        update((prevState) => [...prevState, id]);
      }
    },
    [currentState, update]
  );
};

export const useSkip = (effect: Function, deps: DependencyList, times: number = 1) => {
  const [_times, setTimes] = useState(0);
  useEffect(() => {
    if (_times >= times) {
      return effect();
    } else {
      setTimes(_times + 1);
    }
  }, deps);
};
