import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  fullWidth: {
    minWidth: 128,
    width: '100%',
  },
  notFullWidth: {
    minWidth: 128,
    width: 128,
  },
}));

interface SelectBoxProps {
  isMinWidth?: boolean;
  label: string;
  options: { id: string; name: string }[];
  required: boolean;
  select: (value: string) => void;
  value: string;
}

const SelectBox = (props: SelectBoxProps) => {
  const classes = useStyles();
  const formControlClass = props.isMinWidth ? classes.notFullWidth : classes.fullWidth;

  return (
    <FormControl className={formControlClass}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value}
        required={props.required}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => props.select(e.target.value as string)}
      >
        {props.options.map((value: { id: string; name: string }) => {
          return (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
