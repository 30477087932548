import React, { FC } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import COCOL from 'assets/img/icon.png';
import NoProfile from 'assets/img/no-profile.png';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'flex-start',
      paddingRight: '0 !important',
      '& div.bubble': {
        background: theme.palette.primary.light,
        borderRadius: 4,
        color: theme.palette.grey[700],
        fontSize: 14,
        fontWeight: 600,
        padding: 8,
        marginRight: 16,
        maxWidth: '80%',
        width: 'auto',
      },
    },
    reverse: {
      display: 'flex',
      flexFlow: 'row-reverse',
      justifyContent: 'end',
      paddingRight: '0 !important',
      '& div.bubble': {
        background: theme.palette.secondary.light,
        borderRadius: 4,
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        padding: 8,
        marginRight: 16,
        maxWidth: '80%',
        width: 'auto',
      },
    },
  })
);

interface Props {
  text: string;
  type: string;
}

const Chat: FC<Props> = (props) => {
  const { text, type } = props;
  const isQuestion = type === 'question';
  const classes = useStyles();
  const classNames = isQuestion ? classes.row : classes.reverse;

  return (
    <ListItem className={classNames}>
      <ListItemAvatar>
        {isQuestion ? <Avatar alt="icon" src={COCOL} /> : <Avatar alt="icon" src={NoProfile} />}
      </ListItemAvatar>
      <div className={'bubble'}>{text}</div>
    </ListItem>
  );
};

export default Chat;
