import React, {FC} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CheckoutForm} from "components/Payment";
const STRIPE_PUBLISHED_KEY = (process.env.NODE_ENV === 'production' && process.env.REACT_APP_PROJECT_ID === 'cocol-prod')
  ? "pk_live_51HdRdNASuq021n8MteZykYnsHViEoz6uUgLvKqed6lsbRTml8UM2kSs2b5EyCf5T7hn0GwRRd45vhx6lGtxUTGzy00Dg3P6jVv"
  : "pk_test_51HdRdNASuq021n8MhZYKslFnMncZohZNMEZtSqM2qpqUsHccKmHvJHg8dAFLhJPPeVqPHevfe9tFWryLOvfSbbi400tLzLEi4S";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISHED_KEY);

const CheckoutWrapper: FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default CheckoutWrapper;