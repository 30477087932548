import State from './types';

export const initialState: State = {
  payment: {
    auth_id: '',
    nickname: '',
    isPaidFirst: false,
    isPaidMember: false,
    isSignedIn: false,
    stripeCard: {
      brand: "",
      country: "",
      exp_month: 0,
      exp_year: 0,
      fingerprint: "",
      funding: "",
      last4: ""
    },
    stripeCustomerId: '',
    stripeId: '',
    stripePaymentMethodId: '',
    uid: ''
  },
  loading: {
    state: false,
    text: '',
  },
  users: {
    email: '',
    isSignedIn: false,
    role: '',
    uid: '',
    username: '',
  },
};
